import React, {useState, useContext, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import facebook_icon from '../../assets/images/facebook_icon.png'
import google_icon from '../../assets/images/google+_icon.png'
import apple_icon from '../../assets/images/apple_icon.png'
import { pathOr } from '../../utils/utils'
import { UserContext } from '../../contexts/user-context';
import useFirebaseLogin from '../../hooks/useFirebaseLogin';
import { useFirebaseSignOutFromAllDevices } from '../../hooks/useFirebaseAuthOps';

const getFromPath = pathOr(null)(['location', 'state', 'from', 'pathname']);

const Login = (props) => {
  const redirectTo = getFromPath(props);
  const history = useHistory();
  const { user } = useContext(UserContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const signIn = useFirebaseLogin(setError, (x) => console.log(x))
  const signOutFromAllDevices = useFirebaseSignOutFromAllDevices(setError, setSuccessMessage)

  const signInWithEmailAndPasswordHandler = (email, password) => (event) => {
    event.preventDefault();
    signIn({
      email,
      password
    })
  };

  const signOutFromAllDevicesHandler = (email, password) => (event) =>{
    event.preventDefault();
    signOutFromAllDevices()
  }

  const signInWithProvider = provider => event => {
    event.preventDefault();
    signIn({ provider });
  }

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;

    if(name === 'userEmail') {
      setEmail(value);
    }
    else if(name === 'userPassword'){
      setPassword(value);
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if(user && (user.subscriptionError === undefined)) {
      redirectTo
        ? history.push(redirectTo)
        : history.push("/book");
    }
  }, [user, redirectTo])
  /* eslint-enable */

  return (
    <div className="pt-24 bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <img className="mx-auto h-12 w-auto" src={logo} alt="SAMF"/>
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-brand-primary">
          Sign in to your account
        </h2>
        <div className="mt-2 text-center text-large">
          Use the same account details as you use for the SAMF mobile app
        </div>

        {/*<p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
          Or
          <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
             start your 14-day free trial
          </a>
        </p>
*/}
        {error !== null && <div className = "py-4 bg-red-600 w-full text-white text-center mt-3">{error}</div>}
        {
          error === 'The limit number of active devices has been reached!'
          ?
            <div className='bg-gray-100 p-4 mt-2'>
              <div>
                You need to either log out from another device that you currently use or you can opt to sign out from all devices by tapping the button below.
              </div>
              <button
                onClick = {signOutFromAllDevicesHandler(email, password)}
                className="block mx-auto justify-center py-2 px-4 border border-red-900 border-8 text-sm font-medium rounded-md text-red-900  hover:bg-gray-200 focus:outline-none focus:border-green-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                Log out from all devices
              </button>

            </div>
          : ''
        }
        {successMessage != null && <div className = "py-4 bg-green-400 w-full text-white text-center mt-3">{successMessage}</div>}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                name="userEmail"
                value = {email}
                placeholder="E.g: faruq123@gmail.com"
                id="userEmail"
                onChange = {onChangeHandler}
                type="email"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="userPassword"
                  value = {password}
                  placeholder="Your Password"
                  id="userPassword"
                  onChange = {onChangeHandler}
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>

            {/*<div className="mt-6 flex items-center justify-between">
              <div className="flex items-center">
                <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>
                <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-5">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                  Forgot your password?
                </a>
              </div>
            </div>
*/}

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  onClick = {signInWithEmailAndPasswordHandler(email, password)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-secondary hover:bg-green-400 focus:outline-none focus:border-green-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  Sign in
                </button>
              </span>
            </div>
          </form>
          <div className='mt-3 mb-3'>
            <div className='flex flex-row-reverse'>
              <Link to="/forgot-password" className='text-gray-500 hover:text-gray-900 cursor-pointer'>
                Forgot password?
              </Link>
            </div>
          </div>
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm leading-5">
                <span className="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-3">
              <div>
                <span className="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={ signInWithProvider('facebook') }
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    aria-label="Sign in with Facebook">
                    <img className="mx-auto h-6 w-auto" src={facebook_icon} alt="Facebook"/>
                  </button>
                </span>
              </div>

              <div>
                <span className="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={ signInWithProvider('google') }
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    aria-label="Sign in with Google">
                    <img className="mx-auto h-6 w-auto" src={google_icon} alt="Google"/>
                  </button>
                </span>
              </div>

              <div>
                <span className="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={ signInWithProvider('apple') }
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    aria-label="Sign in with Apple">
                    <img className="mx-auto h-6 w-auto" src={apple_icon} alt="Apple"/>
                  </button>
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Login;
