import "../../styles/index.css";

import Footer from "../../components/footer/footer";
import React from "react";
import appstore_badge from "../../assets/images/common/appstore-badge.png";
import gplay_badge from "../../assets/images/common/gplay-badge.png";
import samf_book from "../../assets/images/common/samf_book_14th_edition.png";
import two_phones from "../../assets/images/homepage/two_phones_cropped.png";

const BuySAMF = () => {
  return (
    <>
      <div className="bg-white mb-10">
        <div className="flex justify-between">
          <div className="w-full lg:w-3/5">
            <div className="mx-auto py-6 px-4 max-w-screen-xl px-10 lg:px-20 lg:py-14">
              <div className="space-y-6 sm:space-y-5">
                <h1 className="text-4xl leading-9 font-extrabold tracking-tight text-brand-primary">
                  Buy SAMF
                </h1>
              </div>
            </div>
            <div className="bg-brand-primary text-white pr-28 pl-10 lg:px-20 pt-8  rounded-r-full">
              <h3 className="text-2xl leading-9 font-extrabold tracking-tight">
                <b>
                  Welcome to the future. The SAMF - trusted since 1988 - is now available digitally.{" "}
                </b>
              </h3>
              <div className="mt-3">
                The app is available on the Apple Store and Play Store. Access
                the links below and follow the payment instructions.
              </div>
              <div className="flex pb-4 flex-wrap">
                <a
                  className="mr-5 mt-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.aviro.samf"
                >
                  <img
                    className="store-badge"
                    src={gplay_badge}
                    alt="Available on Google Play"
                  />
                </a>
                <a
                  className="mt-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/us/app/samf/id1574380535"
                >
                  <img
                    className="store-badge"
                    src={appstore_badge}
                    alt="Available on App store"
                  />
                </a>

                {/*<img className=' store-badge pt-3 pb-6 pl-3' src={ appstore_badge } alt="Available on App Store"/>*/}
              </div>
            </div>
          </div>
          <div className="hidden lg:block w-2/5 py-10">
            <img
              className="float-right mx-10 md:mr-5"
              style={{ maxHeight: "450px" }}
              src={two_phones}
              alt="SAMF Mobile app"
            />
          </div>
          <div className="sm:block lg:hidden hidden w-1/6"></div>
        </div>

        <div className="flex justify-between mt-5">
          <div className="sm:block  w-1/12"></div>
          <div className="w-full lg:w-6/12 pt-20">
            <div className="bg-brand-primary text-white pr-20 pl-32 pt-10 pb-10 rounded-l-full">
              <h3 className="text-2xl leading-9 font-extrabold tracking-tight mb-3">
                App price
              </h3>
              <div>
                <ul className="ml-5 list-disc">
                  <li className="">
                  R889.99/year or R87.99/month (including VAT).
                  </li>
                  <li>
                    For sales queries:{" "}
                    <a
                      href="mailto: annoesjka.swart@uct.ac.za"
                      className="text-brand-secondary"
                    >
                       annoesjka.swart@uct.ac.za
                    </a>
                  </li>
                </ul>
              </div>
              <h3 className="text-2xl leading-9 font-extrabold tracking-tight mb-2 mt-4">
                <b>Printed edition:</b>
              </h3>
              <div>
                Email the South African Medical Association at{" "}
                <a
                  href="mailto:samf@samedical.org"
                  className="text-brand-secondary"
                >
                  samf@samedical.org
                </a>{" "}
                for enquiries{" "}.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BuySAMF;
