import React from "react";
import { Link } from "react-router-dom";

// import aviro_logo from "../../assets/images//homepage/aviro_logo_updated.png";
import sama_logo from "../../assets/images/homepage/sama_logo_new.png";
import uct_logo from "../../assets/images/homepage/uct_logo_new.png";
import samf_logo from "../../assets/images/homepage/samf_logo_mini.png";

const Footer = () => {
  return (
    <div className="bg-gray-300">
      <div className="max-w-screen-xl mx-2 md:mx-12 lg:mx-20 py-4 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex justify-between items-center border-b-2 border-gray-400 pb-4">
          <h4 className="uppercase text-brand-primary text-md font-bold">
            Quicklinks
          </h4>
          <div className="">
            {/* <img className="w-40" src={aviro_logo} alt="Aviro" /> */}
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-1 flex flex-col py-4">
            <h5 className="font-bold text-sm text-brand-primary pb-4 uppercase">
              SAMF
            </h5>
            <nav className="flex flex-col">
              <Link
                to="/login"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                Online SAMF
              </Link>
              <Link
                to="/buy-samf"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                Buy SAMF
              </Link>
              <Link
                to="/faq"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                FAQ
              </Link>
              <Link
                to="/about-us"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                About us
              </Link>
              {/*<a*/}
              {/*  href="https://shop.mpconsulting.co.za/collections/books/products/south-african-medicines-formulary-samf-13th-edition"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*  className="text-xs text-brand-primary hover:text-gray-900 pb-4">*/}
              {/*  Buy SAMF Book*/}
              {/*</a>*/}
            </nav>
          </div>
          <div className="col-span-1 flex flex-col py-4">
            <h5 className="font-bold text-sm text-brand-primary pb-4 uppercase">
              SAMF Legal
            </h5>
            <nav className="flex flex-col">
              <Link
                to="/tos"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                Terms and conditions
              </Link>
              <Link
                to="/privacy"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                Privacy policy
              </Link>
              <Link
                to="/cookie-policy"
                className="text-xs text-brand-primary hover:text-gray-900"
              >
                Cookie Policy
              </Link>
            </nav>
          </div>
          <div className="col-span-1 flex flex-col py-4">
            <h5 className="font-bold text-sm text-brand-primary pb-4 uppercase">
              Contact
            </h5>
            <div className="flex flex-col">
              <Link
                to="/contact"
                className="text-xs text-brand-primary hover:text-gray-900 pb-4"
              >
                Contact us
              </Link>
              {/*<p className="text-xs font-bold text-brand-primary hover:text-gray-900 pb-4">*/}
              {/*  Email*/}
              {/*</p>*/}
              {/*<p className="text-xs text-brand-primary hover:text-gray-900">*/}
              {/*  pha-mic@uct.ac.za*/}
              {/*</p>*/}
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-end flex-wrap">
            <img className="w-24 h-auto" src={sama_logo} alt="SAMA" />
            <img className="w-24" src={uct_logo} alt="UCT" />
            <img className="w-24" src={samf_logo} alt="SAMF" />
          </div>
        </div>
        <div className="flex justify-center border-t-2 border-gray-400 pt-4">
        <p className="text-xs text-gray-500">SAMF @ {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
