import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import HowToFindInfo from "./how-to-find-info";
import WhatInfoToFind from "./what-info-to-find";

const OnboardingGuide = () => {

  return (
    <Tabs>
      <TabList>
        <Tab><b className="text-brand-primary">How to find information?</b></Tab>
        <Tab><b className="text-brand-primary">What information can you find?</b></Tab>
      </TabList>

      <TabPanel>
        <div className="p-2">
          <HowToFindInfo/>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="p-2">
          <WhatInfoToFind/>
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default OnboardingGuide;

