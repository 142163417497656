import {
  BookNavigationContext,
  BookNavigationProvider,
} from "../../contexts/book-navigation-context";
import {
  ChaptersContext,
  ChaptersProvider,
} from "../../contexts/chapters-context";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { findInTree, orValue } from "../../utils/utils";

import All from "./all/all";
import { BookContentsProvider } from "../../contexts/book-contents-context";
import { FavoritesProvider } from "../../contexts/favorites-context";
import Favs from "./favs/favs";
import Prescribing from "./prescribing/prescribing";
import PrivacyModal from "../../components/privacy-modal/privacy-modal";
import Search from "../../components/search/search";
import MobileModal from "../../components/mobile-modal/mobile-modal";

const SubNavLink = ({ to, children, activeOnlyWhenExact }) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Link
      to={to}
      className={`mr-6 inline-flex items-center px-1 pt-1 border-b-2 border-${
        match ? "brand-secondary" : "transparent"
      } text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-${
        match ? "brand-secondary" : "gray-300"
      } transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
};

const BookNav = () => {
  const { url } = useRouteMatch();

  return (
    <nav className="bg-white shadow">
      <div className="max-w-full mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">
            <div className="hidden lg:ml-0 lg:flex">
              <SubNavLink to={`${url}/all`}>All</SubNavLink>
              <SubNavLink to={`${url}/favourites`}>Favourites</SubNavLink>
              <SubNavLink to={`${url}/prescribing`}>Prescribing</SubNavLink>
              <SubNavLink to={`${url}/help`}>Help</SubNavLink>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-start">
            <Search />
          </div>
          <div className="flex items-center lg:hidden">
            {/*<!-- Mobile menu button -->*/}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
            >
              {/*<!-- Icon when menu is closed. -->
              <!-- Menu open: "hidden", Menu closed: "block" -->*/}
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/*<!-- Icon when menu is open. -->
              <!-- Menu open: "block", Menu closed: "hidden" -->*/}
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:ml-4 lg:flex lg:items-center">
            {/* <button
              className="flex-shrink-0 p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
              aria-label="Notifications"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button> */}
          </div>
        </div>
      </div>

      {/*<!--
        Mobile menu, toggle classNamees based on menu state.

        Menu open: "block", Menu closed: "hidden"
      -->*/}
      <div className="hidden lg:hidden">
        <div className="pt-2 pb-3">
          <Link
            to={`${url}`}
            className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
          >
            Browse
          </Link>
          <Link
            to={`${url}/favorites`}
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            Favorites
          </Link>
          <Link
            to={`${url}/prescribing`}
            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
          >
            Prescribing
          </Link>
        </div>
      </div>
    </nav>
  );
};

const BookOtherContents = () => {
  const { topic, ref } = useParams();
  const chapters = useContext(ChaptersContext);
  const { selectedItem, updateSelected } = useContext(BookNavigationContext);

  useEffect(() => {
    if (!selectedItem || selectedItem.ref !== ref) {
      const selectedFromTopic = chapters
        .map(findInTree("ref", ref))
        .reduce(orValue, null);

      selectedFromTopic && updateSelected(selectedFromTopic);
    }
  }, [ref, chapters, selectedItem, updateSelected]);

  if (topic === "favourites") {
    return <Favs />;
  }
  if (topic === "prescribing") {
    return <Prescribing />;
  }

  return <All />;
};

const Book = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <PrivacyModal />
      <MobileModal/>
      <ChaptersProvider>
        <BookNavigationProvider>
          <BookContentsProvider>
            <FavoritesProvider>
              <BookNav />
              <div className="mt-1">
                <Switch>
                  <Route path={`${path}/:topic/:ref?`}>
                    <BookOtherContents />
                  </Route>
                  <Route path={path}>
                    <All />
                  </Route>
                </Switch>
              </div>
            </FavoritesProvider>
          </BookContentsProvider>
        </BookNavigationProvider>
      </ChaptersProvider>
    </>
  );
};

export default Book;
