import React, { useState, useEffect, useContext } from "react";
import { BookContentsContext } from "../../../contexts/book-contents-context";
import DrugHeader from "../../../components/drug/drug-header";
import DrugBody from "../../../components/drug/drug-body";
import OtherContentHeader from "../../../components/other-content/other-content-header";
import OtherContentBody from "../../../components/other-content/other-content-body";
// import Loader from '../../../components/loader/loader';
import { isEmpty } from "../../../utils/utils";
import OnboardingGuide from "./onboarding-guide";
import samflogo from '../../../assets/images/SAMF_logo.png'

const CurrentContent = ({ handleScroll }) => {
  const { content } = useContext(BookContentsContext);
  const [currentContent, setCurrentContent] = useState({});

  useEffect(() => {
    if (content && content.hasBody) {
      setCurrentContent(content);
      handleScroll && handleScroll()
    }
  }, [content, handleScroll]);

  const isOnBookHelpPage = window.location.pathname === "/book/help";


  const removeTableFixedWidth = (currentContent) => {
    const htmlContent = currentContent.body
    if(htmlContent.indexOf("<table style='width: ") > 0){
      const startIndex = htmlContent.indexOf("<table style='width: ")
      const fromTableOn = htmlContent.substring(startIndex, htmlContent.length)
      const prefixToKeep = "<table style='"
      const endingExpression = "px; "
      const endIndex = fromTableOn.indexOf(endingExpression)
      const toReplace = htmlContent.substring(startIndex + prefixToKeep.length, startIndex + endIndex + endingExpression.length)

      currentContent.body = currentContent.body.replace(toReplace, "")
    }
    return currentContent
  }

  return !isEmpty(currentContent) ? (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="border-b border-gray-200 px-4 py-5 sm:px-6 bg-brand-primary">
        {currentContent.drug ? (
          <DrugHeader content={currentContent} />
        ) : (
          <OtherContentHeader content={currentContent} />
        )}
      </div>
      <div className="px-4 py-5 sm:p-6">
        {currentContent.drug ? (
          <DrugBody content={currentContent} />
        ) : (
          <OtherContentBody content={removeTableFixedWidth(currentContent)} />
        )}
      </div>
    </div>
  ) :
  isOnBookHelpPage ? (
    <OnboardingGuide />
  ) :
  <div className="flex justify-center mt-5">
        <img className="w-80 h-80" src={samflogo} alt="SAMF Logo" />
    </div>
};

export default CurrentContent;

