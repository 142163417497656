import { useState } from 'react';
import { noop } from '../utils/utils'

const useForm = (initialState = {}, handleSubmit = noop, validate = noop) => { 
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState({  });

  const onChange = key => event => { 
    event.preventDefault()
    const { value } = event.currentTarget

    setFormState({ 
      ...formState,
      [key]: value
    });

  };

  const onSubmit = (event) => {
    event.preventDefault()
    const errs = validate(formState);

    setErrors(errs);
    const formAfterSubmit = handleSubmit(formState, errs);
    if(formAfterSubmit) {
      setFormState(formAfterSubmit)
    } else { 
      setFormState({})
    }
    return formAfterSubmit;
  }


  return [formState, onChange, onSubmit, errors]; 
}

export default useForm;

