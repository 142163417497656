import React from 'react'
import Footer from '../../components/footer/footer'

const Contact = () => {

  return (
    <div className='flex flex-col justify-between' style={{minHeight:'95vh'}}>
    <div className="bg-white mx-2 md:mx-12 lg:mx-20">
      <div className="mx-auto py-6 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-20">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h1 className="text-4xl leading-9 font-extrabold tracking-tight sm:text-3xl text-brand-primary">
              Get in touch with us
            </h1>
          </div>
        </div>

        <div className='mt-12 pr-5 flex flex-row justify-between'>
          <div className='w-1/2'>
            <div>
              <div className='mb-3'><b>For medicine-related queries</b></div>
              <div className='mb-1'>+27 (0) 861 100 531</div>
              <div className='mb-1'>+27 (0) 21 406 6829</div>
              <div className='text-brand-primary'><a href='mailto:pha-mic@uct.ac.za'>pha-mic@uct.ac.za</a></div>
            </div>

            <div className='mt-12'>
              <div className='mb-3'><b>For technical issues</b></div>
              <div className='text-brand-primary'><a href='mailto:pha-mic@uct.ac.za'>pha-mic@uct.ac.za</a></div>
            </div>
          </div>
          <div className='w-1/2'>
            <div>
              <div className='mb-4'><b>Comments on the content should be sent to</b></div>
              <div>The Editor, Division of Clinical
                Pharmacology, Faculty of Health Sciences, University of Cape Town, Observatory, 7925</div>
              <div className='text-brand-primary'><a href='mailto:tashni.naidu@uct.ac.za.'>tashni.naidu@uct.ac.za.</a></div>
            </div>

            <div className='mt-12'>
              <div className='mb-3'><b>For sales queries</b></div>
              <div className='text-brand-primary'><a href='mailto:annoesjka.swart@uct.ac.za'> annoesjka.swart@uct.ac.za</a></div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Contact;
