import React, { useContext, useState} from 'react'
import { UserContext } from '../../contexts/user-context';
import UserAvatar from '../../components/user-avatar/user-avatar'
import { auth , firestore} from '../../services/firebase';
import DeleteAccountModal from '../../components/delete-account-modal/delete-account-modal';
import { useHistory } from 'react-router-dom';

const Profile = () => { 
  const {user} = useContext(UserContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();

  
  const deleteAccountData = async (uid) => {
    try {
      // Get user's favorites
      const snapshot = await firestore
        .collection("favorites")
        .where("userId", "==", uid)
        .get();
  
      // Create an array of delete promises for favorites
      const deleteFavoritesPromises = snapshot.docs.map(doc => doc.ref.delete());
  
      // Create a promise to delete user data
      const deleteUserPromise = firestore.collection("users").doc(uid).delete();
  
      // Wait for all deletions to complete
      await Promise.all([...deleteFavoritesPromises, deleteUserPromise]);
  
      console.log("Successfully deleted account data.");
  
    } catch (error) {
      console.error("Error deleting account data:", error);
      throw error;  // Re-throwing to handle it in the main delete function
    }
  }
  
  const handleDeleteProfile = async () => {
    try {
      const currentUser = auth.currentUser;
     
  
      if (currentUser) {
        await deleteAccountData(currentUser.uid);
        await currentUser.delete();
  
        console.log("success in deleting user and their data");
        localStorage.removeItem("user");
        history.push('/login');
      } else {
        console.error("No user currently authenticated");
      }
  
    } catch (error) {
      console.error("Error during user deletion:", error);
    }
  }

  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl leading-9 font-extrabold text-brand-secondary tracking-tight sm:text-4xl">Your profile</h2>
            { /* <p className="text-xl leading-7 text-gray-600">Ornare sagittis, suspendisse in hendrerit quis. Sed dui aliquet lectus sit pretium egestas vel mattis neque.</p> */ } 
          </div>
          <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
            <li className="py-10 px-6 bg-brand-primary text-center rounded-lg xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <div className="flex justify-center items-center">
                  <UserAvatar avatarUrl={ user.avatarUrl } size="32"/>
                </div>
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="font-medium text-lg leading-6 space-y-1">
                    <h4 className="text-white">
                    { user && user.displayName ? user.displayName : '' }
                    </h4>
                    <p className="text-indigo-400"></p>
                  </div>

                  <ul className="flex justify-center space-x-5">
                    <li>
                      <div className="text-gray-400 hover:text-gray-300 transition ease-in-out duration-150">
                      { user && user.email ? user.email : '' }
                      </div>
                    </li>
                    
                  </ul>
                  
                </div>
                <div className="py-2 px-2  bg-red-500 text-center rounded-md xl:px-4 xl:text-left">
                  <button
                  className="text-white text-sm font-semibold w-full hover:opacity-80 transition-opacity"
                  onClick={() => setShowDeleteModal(true)} 
                  >
                   Delete Profile
              </button>
                </div>
        
              </div>
            </li>
          </ul>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteAccountModal
          onClose={() => setShowDeleteModal(false)} 
          onDelete={handleDeleteProfile} 
        />
      )}
    </div>
  )
}

export default Profile;