import React from 'react';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import './mobile-modal.css'; 
import SAMFLogo from "../../assets/images/SAMF_logo.png";

const MobileModal = () => {
  const isMobile = useCheckMobileScreen();

  if (isMobile) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">  
          <img className='mobile-modal-logo' src={SAMFLogo} />
          <p>Please download the SAMF app to access the SAMF book content on mobile.</p>
        </div>
      </div>
    );
  }

  return null;
};

export default MobileModal;
