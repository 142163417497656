import React from "react";
import 'react-tabs/style/react-tabs.css';
import fluoxetine_image from "../../../assets/images/onboarding/how_to_find/Fluoxetine.png"
import fav from "../../../assets/images/onboarding/how_to_find/fav.png"
import unfav from "../../../assets/images/onboarding/how_to_find/unfav.png"
import fav_list from "../../../assets/images/onboarding/how_to_find/fav_list.png"
import arrow_right from "../../../assets/images/onboarding/how_to_find/arrow_right.png"
import prescribing from "../../../assets/images/onboarding/how_to_find/prescribing.png"
import search_fluoxetine_dose from "../../../assets/images/onboarding/how_to_find/search_fluoxetine_dose.png"
import search_ibuprofen_indications from "../../../assets/images/onboarding/how_to_find/search_ibuprofen_indications.png"
import search_pregnancy from "../../../assets/images/onboarding/how_to_find/search_pregnancy.png"
import search_tuberculosis from "../../../assets/images/onboarding/how_to_find/search_tuberculosis.png"
import OnboardingItem from "./onboarding-item";

const HowToFindInfo = () => {

  return (
    <div>
          <div className="pb-4">
            There are several ways to find the information you are looking for:
          </div>
          <OnboardingItem
            itemTitle='1.  You can search using one or more keyword/term via the <b>search box</b>. These terms can include
            generic/trade names as well as disease/medical conditions e.g. insulin, fluoxetine dose, tuberculosis, Prozac.'
          >
            <div className="flex justify-between">
              <img src={ search_fluoxetine_dose } alt="Fluoxetine dose"/>
              <img src={ search_ibuprofen_indications } alt="Ibuprofen indications"/>
              <img src={ search_tuberculosis } alt="Tuberculosis"/>
              <img src={ search_pregnancy } alt="Pregnancy"/>
            </div>
          </OnboardingItem>

          <div className="mt-2">
            <OnboardingItem
              itemTitle='2.  Clicking on the <b>All</b> tab to find a chapter (A to V). See list on the left.
              The > symbol indicates that the term will drop down further.'
            >
              <div className='leading-7'>
                For example, in order to access <i>Fluoxetine</i> drug information you should go through the following steps:
                <div className="pl-4">
                  <div className="flex flex-row">
                    <div className="pr-1">a. Click on </div>
                    <div className="flex flex-row">
                      <span className="pr-1 text-brand-secondary font-bold">N</span>
                      <div className="font-bold text-brand-primary">Central Nervous System</div>
                    </div>
                    <div>
                      . This will display the list of chapter <span className="text-brand-secondary font-bold">N</span>'s
                      items.
                    </div>
                  </div>
                  <div>b. Select section <i><b><span className="text-gray-600"> N06 Psychoanaleptics</span></b></i>.</div>
                  <div>
                    c. Choose <i><b><span className="text-gray-600">Antidepressants</span></b></i>.
                    This will also display introductory information on antidepressants.
                  </div>
                  <div>
                    d. After that, choose
                    <i><b><span className="pl-1 text-gray-600">Selective serotonin reuptake inhibitors (SSRIs)</span></b></i>.
                  </div>
                  <div>
                    e. Now you'll be able to see <i><b><span className="text-gray-700">Fluoxetine</span></b></i> displayed
                    in the list of the previously opened section's descendents. Click on the drug's name and you
                    should see all the drug information related to this drug.
                  </div>
                </div>
                <img src={ fluoxetine_image } alt="Fluoxetine"/>
              </div>
            </OnboardingItem>


          </div>

          <div className="mt-2">
            <OnboardingItem
              itemTitle='3.  Using the <b>Favourites</b> tab once you have selected your favourite medicines by clicking
                on the heart symbol at the top of the monograph.'
            >
              <div>
                <div className="flex items-center justify-center pb-2">
                  <img className="pr-3" src={ unfav } alt="Fluoxetine"/>
                  <div className="pr-3">
                    <img className="h-11" src={ arrow_right } alt="arrow_right"/>
                  </div>
                  <img className="pt-2" src={ fav } alt="Fluoxetine"/>
                </div>
                <div className='pb-3'>
                  For example, after marking <i><b><span className="text-gray-700">Fluoxetine</span></b></i> as favourite,
                  you'll be able to see it in a quick-access list available in the <b>Favourites</b> tab.
                </div>
                <img src={ fav_list } alt="List of favourites"/>
              </div>
            </OnboardingItem>
          </div>

          <div className="mt-2">
            <OnboardingItem
              itemTitle=' 4.  Clicking on the <b>Prescribing</b> tab to find information on prescribing in special populations e.g.
              liver disease, paediatrics, QT prolongation.'
            >
              <img src={ prescribing } alt="Prescribing_liver_disease"/>
            </OnboardingItem>
          </div>
      </div>
  );
};

export default HowToFindInfo;

