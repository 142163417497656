import React, {useContext, useState} from 'react';
import logo from '../../assets/images/logo.png';
import {UserContext} from '../../contexts/user-context';
import {firestore} from "../../services/firebase";
import {auth} from "firebase";




const DeleteAccount = (props) => {

  const {user} = useContext(UserContext)
  const [userDetails, setUserDetails] = useState({
email: "",
      password: ""
  })
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

    const deleteAccountData = async (uid) => {
        try {
            // Get user's favorites
            const snapshot = await firestore
                .collection("favorites")
                .where("userId", "==", uid)
                .get();

            // Create an array of delete promises for favorites
            const deleteFavoritesPromises = snapshot.docs.map(doc => doc.ref.delete());

            // Create a promise to delete user data
            const deleteUserPromise = firestore.collection("users").doc(uid).delete();

            // Wait for all deletions to complete
            await Promise.all([...deleteFavoritesPromises, deleteUserPromise]);

            console.log("Successfully deleted account data.");

        } catch (error) {
            console.error("Error deleting account data:", error);
            throw error;  // Re-throwing to handle it in the main delete function
        }
    }

    const signInAndDeleteUser = async () => {
      try {
        // Sign in the user
        const userCredential = await auth().signInWithEmailAndPassword(userDetails.email, userDetails.password);
        const user = userCredential.user;
    
        // Delete the user data
        await deleteAccountData(user.uid);
    
        // Delete the Firebase authentication user
        await user.delete();

        setSuccessMessage('Account deleted successfully');

    
        console.log('User deleted successfully');
      } catch (error) {
        console.error('Error signing in or deleting user:', error.message);
        setError(error.message);
      }
    };
    

  const onChangeHandler = (event) => {
    let val = {[event.target.name]: event.target.value}
    setUserDetails({...userDetails, ...val})
  }


  return (
      <div className="pt-24 bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <img className="mx-auto h-12 w-auto" src={logo} alt="SAMF"/>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-brand-primary">
Confirm your account details in order to proceed with deletion          </h2>
          <div className="mt-2 text-center text-large">
            Use the same account details as you use for the SAMF mobile app
          </div>

          {error !== null && <div className="py-4 bg-red-600 w-full text-white text-center mt-3">{error}</div>}


          {successMessage != null &&
              <div className="py-4 bg-green-400 w-full text-white text-center mt-3">{successMessage}</div>}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                  Email address
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                      name="email"
                      value={userDetails.email}
                      placeholder="E.g: faruq123@gmail.com"
                      id="userEmail"
                      onChange={onChangeHandler}
                      type="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                </div>
              </div>

              <div className="mt-6">
                <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                  Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                      name="password"
                      value={userDetails.password}
                      placeholder="Your Password"
                      id="userPassword"
                      onChange={onChangeHandler}
                      type="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                </div>
              </div>

              <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                    onClick={() => signInAndDeleteUser()}
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-secondary hover:bg-green-400 focus:outline-none focus:border-green-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  Delete Account
                </button>
              </span>
              </div>
            </div>

          </div>
        </div>
      </div>
  )
}

export default DeleteAccount;
