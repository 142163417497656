import { auth } from '../services/firebase'
import { useState } from "react";
import axios from "../services/axios";

export const useForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState({})

  return [
    ({form}) => {
      setLoading(true);

      auth.sendPasswordResetEmail(form.email)
        .then(() => {
          setLoading(false)
          setReply({'success': true})
        })
        .catch((error) => {
          console.error("Error resetting password", error);
          setLoading(false)
          setReply({'error': error.message})
        });
    },
    loading,
    reply
  ]

}

export const useFirebaseSignOutFromAllDevices = (onError, onSuccess) => {
  const signIn = () => {

    auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function (idToken) {
      axios.defaults.headers.common['Authorization'] = "Bearer " + idToken;
      try {
        await axios.post('/logout-all-devices');
        await auth.signOut().then(() => {});
        onSuccess("Please wait a few minutes before trying to log in again");
        onError(null)
        return {success: true}
      } catch (e){
        let errorMessage = 'Unfortunately, the request could not be executed'
        return {success: false, errorMessage: errorMessage}
      }
    })
  }
  return signIn;
}

