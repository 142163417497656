import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom';
import Footer from '../../components/footer/footer';
import { firestore } from '../../services/firebase';
import { createMarkup } from '../../utils/utils';

const matchRouteToDoc = {
  'privacy': 'privacy_policy',
  'tos': 'terms_and_conditions',
  'abbreviations': 'abbreviations',
  'medicine-info-services': 'medicines_information_services',
  'cookie-policy': 'cookie_policy'
}

const StaticPage = () => {
  const { url } = useRouteMatch();
  const [html, setHtml] = useState(null)

  useEffect(() => {
    const addr = matchRouteToDoc[url.substr(1)];
    const ref = firestore.collection('drawer-items').doc(addr)

    ref.get().then((doc) => {
      if(doc.exists) {
        setHtml(doc.data().htmlContent)
      }
    } )

  }, [url])

  return html && (
    <div className="h-screen flex flex-col justify-between">
      <div className="server-content py-8 mx-2 md:mx-12 ">
        <div dangerouslySetInnerHTML={ createMarkup(html) }/>
      </div>
      <Footer/>
    </div>
  )
}

export default StaticPage
