import React, { useState, useContext, useEffect } from 'react'
import ReactGA from 'react-ga';
import { UserContext } from '../../contexts/user-context'
import { FavoritesContext } from '../../contexts/favorites-context'
import favourited from '../../assets/images/favourited_icon.png';
import notFavourited from '../../assets/images/not_favourited_icon.png';

import { firestore } from '../../services/firebase'

const includedInFavs = ref => favs => favs.reduce((acc, next) => {
  return next.ref === ref ? next : acc;
}, false)

const FavMark = ({item}) => {
  const favorites = useContext(FavoritesContext);
  const { user } = useContext(UserContext)
  const [isFav, setIsFav] = useState(false)
  const [favId, setFavId] = useState(null)

  const addFav = (item) => {
    const favsRef = firestore
      .collection('favorites')
      .doc()

    ReactGA.event({
      category: 'Favorites',
      action: 'Add favourite',
      label: item.textTitle
    });

    const data = {
      ATCCode: item.extraData.ATCCode || null,
      ScheduleCode: item.extraData.ScheduleCode || null,
      name: item.textTitle,
      htmlTitle: item.name,
      uniqueRef: item.ref,
      contentId: item.id,
      date: new Date(),
      userId: user.uid,
      chapterCode: item.chapterCode,
      shoudDisplayDescription: true,
      parentRef: item.path[1].ref,
      routeOfAdministration: item.extraData.routeOfAdministration || null,
      EML: item.extraData.EML || null,
      synonyms: item.extraData.synonyms || null,
      specialPopulation: item.extraData.specialPopulation || null,
      INN: item.extraData.INN || null,
      isFavorite: true,
    }

    favsRef
      .set(data)
      //.then(() => setIsFav(true))
  }

  const removeFav = (item) => {
    const favsRef = firestore
      .collection('favorites')
      .doc(favId)

    favsRef
      .delete()
      //.then(() => setIsFav(false))
  }

  useEffect(() => {
    // console.log('favorites', favorites)
    const favorite = includedInFavs(item.ref)(favorites);

    if(favorite) {
      setIsFav(true);
      setFavId(favorite.favId)
    } else {
      setIsFav(false)
      setFavId(null)
    }
  }, [item, favorites])


  return (

    <button onClick={ isFav ? () => removeFav(item) : () => addFav(item) }>
    {isFav ? (
      <img
        className="h-8 w-auto"
        src={favourited}
        alt=""
      />
    ) : (
      <img
        className="h-8 w-auto"
        src={notFavourited}
        alt=""
      />
    )}
    </button>
  )
}

export default FavMark;
