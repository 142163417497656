import React from 'react'
import Footer from '../../components/footer/footer'

const About = () => {

  return (
    <>
    <div className="bg-white mx-2 md:mx-12 lg:mx-20">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-2xl text-brand-primary">
              About SAMF
            </h2>
          </div>
          <div className="lg:col-span-2">
            <div className="space-y-5 sm:space-y-4">
              <p className="text-sm">
                Regarded as the gold standard of medicines information and clinical management protocols in South Africa,
                the South African Medicines Formulary (SAMF), first published in 1988, is a trusted source of information
                and provides up-to-date, reliable, unbiased and evaluated information on medicines.
              </p>
              <p className="text-sm">
                Currently on its 15<sup>th</sup> edition, it has become a standard health
                practitioner’s medicine reference guide, respected for accuracy of content and independence from the
                pharmaceutical industry. It is now available as a mobile application, supported by a web portal.
              </p>
              <p className="text-sm">
                The application is aligned with the book and the content is updated 6-monthly.
              </p>
            </div>
            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Features include
              </h4>
              <ul className="list-disc pl-5 text-sm">
                <li>Quick, easy access</li>
                <li>Powerful search function using multiple search terms including generic name, trade names and medical conditions</li>
                <li>Voice search function (Android only)</li>
                <li>Regular updates</li>
                <li>Advert free</li>
                <li>Not influenced by the pharmaceutical industry</li>
                <li>Favourites list</li>
                <li>In-app support from the Medicines Information Centre, UCT</li>
              </ul>
            </div>
            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                The SAMF app contains 88 clinical sections, including
              </h4>
              <ul className="list-disc pl-5 text-sm">
                <li>Over 1 100 medicine profiles</li>
                <li>Over 2 300 dosing regimens</li>
                <li>Over 4 000 registered proprietary preparations</li>
                <li>122 disease specific clinical notes</li>
                <li>Prescribing guidance in specialised clinical situations such as kidney impairment, porphyria, pregnancy and paediatrics</li>
              </ul>
            </div>
            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Gold standard content
              </h4>
              <ul className="list-disc pl-5 text-sm">
                <li>Produced by the top university in Africa: The University of Cape Town (UCT)</li>
                <li>Excellent clinical utility – simple and concise information</li>
                <li>Editors and contributors are respected leaders in drug policy in the country</li>
                <li>Unbiased information – no pharmaceutical industry funding or advertising</li>
                <li>The trusted medicines formulary in South Africa since 1988</li>
              </ul>
            </div>
            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Expert opinion/authors
              </h4>
              <p className="text-sm">
                Each section of the SAMF is reviewed by experts in their relevant fields. These experts hail from
                a range of public and private medical and academic institutions, including the Universities of Cape
                Town, Stellenbosch, the Western Cape and Witwatersrand. The integrity of the information has given the
                publication legal status and it can be used in a court of law to defend medico-legal disputes.
              </p>
            </div>
            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Medical disclaimer
              </h4>
              <p className="text-sm">
                All content provided in the application is for informational, educational and assistive purposes only,
                and is not a substitute for the professional judgement of healthcare professionals diagnosing and
                treating patients. UCT and the South African Medical Association (SAMA) do not provide medical advice
                or medical or diagnostic services through the application. Any reliance on the content obtained on or
                through the application is at your own risk.
              </p>
            </div>
            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Publisher and partner
              </h4>
              <p className="text-sm">
                The SAMF is a joint initiative of the Division of Clinical Pharmacology at the University of Cape
                Town and the South African Medical Association.
              </p>
            </div>

            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Prescribed reference book/app
              </h4>
              <p className="text-sm">
                The SAMF is a prescribed reference in medical, pharmacy and nursing schools and is a mandatory
                reference source in pharmacies, as required by the Good Pharmacy Practice standards of the South
                African Pharmacy Council.
              </p>
            </div>

            <div className="mt-8 space-y-5 sm:space-y-4">
              <h4 className="text-l leading-9 font-extrabold tracking-tight text-brand-primary">
                Sections available
              </h4>
              <ul className="list-disc pl-5 text-sm">
                <li>Alimentary tract and metabolism</li>
                <li>Blood and blood-forming organs</li>
                <li>Cardiovascular system</li>
                <li>Dermatologicals</li>
                <li>Genitourinary system and sex hormones</li>
                <li>Systemic hormonal preparations, excluding sex hormones and insulins</li>
                <li>General anti-infectives for systemic use</li>
                <li>Antineoplastic and immunomodulating agents</li>
                <li>Musculoskeletal system</li>
                <li>Central nervous system</li>
                <li>Antiparasitic products</li>
                <li>Respiratory system</li>
                <li>Sensory organs</li>
                <li>Contrast media</li>
                <li>Emergency treatment of poisoning</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default About;
