import algoliasearch from 'algoliasearch/lite'
import config from '../config'

const { appId, apiKey } = config.algolia
const searchClient = algoliasearch(
  appId, 
  apiKey
);

export default searchClient;
