import React, {useContext} from 'react'
import { ChaptersContext } from '../../../contexts/chapters-context';
import TreeNode from '../../../components/tree/tree';
import CurrentContent from '../common/current-content'
import { pipe, filter, pathOr, head } from '../../../utils/utils';
import BookLayout from '../common/book-layout';

const getPrescribingChapter = pipe(
  filter(c => c.ref === "MFMZZ8IDTFWdWrN2bCr4"),
  head,
  pathOr([])(['children']),
  filter(x => !["Ym1LSjPrcVtSMLnjvE02", "TkmwYnUrhkVMUmgG5Gvf"].includes(x.ref))
)

const Prescribing = () => {
  const chapters = useContext(ChaptersContext)
  const prescribingSections = getPrescribingChapter(chapters)
  
  return (
    <BookLayout 
      onLeftPane={() => <>{prescribingSections.map((c, i) => 
        <TreeNode area='prescribing'node={c} key={i}/>)}</>
      }
      onRightPane={(handleScroll) => <CurrentContent handleScroll={ handleScroll } />}
    />   
  
  )
}

export default Prescribing;
