import * as firebase from 'firebase/app';
import config from '../config';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = config.firebase

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export default firebase;
