import React, { createContext, useEffect, useState, useContext } from "react";
import { firestore } from "../services/firebase";
import { BookNavigationContext } from "./book-navigation-context";
import { isEmpty } from "../utils/utils";

const hasActualContent = (item) => !isEmpty(item.text.textContent);

const getContentProp = (prop) => (item) => {
  if (item.drug) {
    return item.drug[prop];
  }

  if (item.category) {
    return item.category[prop];
  }

  if (item.supercategory) {
    return item.supercategory[prop];
  }

  if (item.section) {
    return item.section[prop];
  }

  if (item.chapter) {
    return item.chapter[prop];
  }
};

const getItemPath = item => { 
  let path = [];

  if(item.drug) { 
    path.push({ 
      ref: item.drug.uniqueRef, 
      name: item.drug.htmlTitle 
    })
  }

  if(item.category) { 
    path.push({ 
      ref: item.category.uniqueRef, 
      name: item.category.htmlTitle 
    })
  }

  if(item.supercategory) { 
    path.push({ 
      ref: item.supercategory.uniqueRef,
      name: item.supercategory.htmlTitle
    })
  }

  if(item.section) { 
    path.push({ 
      ref: item.section.uniqueRef,
      name: item.section.htmlTitle
    })
  }

  if(item.chapter){ 
    path.push({ 
      ref: item.chapter.uniqueRef,
      name: item.chapter.htmlTitle
    })
  }

  return path;
}

const getChapterCode = item => { 
  if(item.chapter) { 
    return item.chapter.code
  }

  return null
}

const prepContent = (id) => (item) => {
  if (item.drug) {
    return {
      name: getContentProp('htmlTitle')(item),
      textTitle: getContentProp('title')(item),
      extraData: item.extraData,
      body: item.text.content,
      drug: true,
      hasBody: true,
      ref: item.uniqueRef,
      id,
      path: getItemPath(item),
      chapterCode: getChapterCode(item)
    };
  }

  return {
    name: getContentProp('htmlTitle')(item),
    textTitle: getContentProp('title')(item),
    body: item.text.htmlContent,
    hasBody: hasActualContent(item),
    ref: item.uniqueRef,
    id,
    path: getItemPath(item),
    chapterCode: getChapterCode(item)
  };
};

const updateWith = append => item => ({ ...item, ...append })

export const BookContentsContext = createContext({ content: null });
export const BookContentsProvider = ({ children }) => {
  const { selectedItem } = useContext(BookNavigationContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false)

  const updateContent = (opts) => { 
    setContent(updateWith(opts)(content))
  }

  useEffect(() => {
    if (selectedItem) {
      setLoading(true)
      const contentRef = firestore
        .collection("book-contents")
        .doc(selectedItem.id);

      const unsubscribe = contentRef.onSnapshot((doc) => {
        // console.log(doc.data())
        setContent(prepContent(selectedItem.id)(doc.data()))
        setLoading(false)
      });

      return unsubscribe;
    }
  }, [setContent, selectedItem]);

  return (
    <BookContentsContext.Provider value={{ content, updateContent, loading }}>
      {children}
    </BookContentsContext.Provider>
  );
};
