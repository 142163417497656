import React from 'react'
import Footer from '../../components/footer/footer'
// import dawn_avatar from "../../assets/images/common/dawn_cropped.png";
// import marc_avatar from "../../assets/images/common/marc_pic.jpg";

import tashni_avatar from "../../assets/images/common/tashni_bw_crop.jpg";
import marc_avatar from "../../assets/images/common/marc_bw.png";

const AboutUs = () => {

  // const coeditors13th = ['Karen I Barnes, MBChB MMed (Clin Pharmacol)', 'Mohamed F Chughlay, MBChB MMed (Clin Pharmacol) FCCP(SA) Dip HIV Man',
  // 'Karen Cohen, MBChB MMed (Clin Pharmacol) FCFP(SA) MSc (Epid)', 'Dip HIV Man Dip Obst', 'Richard Court, MBChB FCP(SA) MMed Dip HIV Man ',
  // 'Eric Decloedt, MBChB MMed (Clin Pharmacol) FCCP(SA) BSc Pharmacology (Hons) PhD Reneé de Waal, MBChB MFPM',
  // 'Isma-eel Ebrahim, MBChB MMed (Clin Pharmacol) FCCP(SA)', 'Rulan Griesel MBChB MMed (Clin Pharmacol) FCCP(SA)',
  // 'Hannah M Gunter, MBChB', 'Mohamed Irhuma, MBBCh FCCP(SA) PGCert Health Informatics (Leeds)',
  // 'ABCP ACCP(USA)', 'Tamara Kredo MBChB MMed Dip HIV Man PhD', 'Gary Maartens, MBChB MMed FCP(SA) DTM&H',
  // 'Helen McIlleron, MBChB PhD (Clin Pharmacol)', 'Phumla Z Sinxadi, MBChB MMed PhD (Clin Pharmacol) DA(SA) CHP (FPM RCP UK)',
  // ]

  const coeditors = ['Karen I Barnes, MBChB MMed (Clin Pharmacol)',
  'Mohamed F Chughlay, MBChB MMed FCCP Dip HIV Man (SA)',
  'Karen Cohen, MBChB MMed (Clin Pharmacol) FCFP(SA) MSc (Epid)',
  'Dip HIV Man Dip Obst',
  'Richard Court, MBChB MMed FCP(SA) Dip HIV Man (SA)',
  'Joel A Dave MBChB (UCT) PhD (UCT) FCP (SA) Cert Endocrinology',
  'Eric Decloedt, MBChB BSc Pharmacology (Hons) MMed (Clin Pharmacol) FCCP(SA) PhD',
  'Reneé de Waal, MBChB MPH (Health Econ) Dip Pharm Med',
  'Gary Maartens, MBChB MMed FCP(SA) DTM&H',
  'Phumla Z Sinxadi, MBChB MMed (Clin Pharmacol) PhD (Clin Pharmacol) DA(SA)',
  'Cert (Human Pharmacol)',
  'Gayle Tatz MBChB'
]

  // const contributors13th = ['Dr A Abulfathi (Division of Pharmacology, University of Stellenbosch)',
  //   'Prof G Ainslie (Department of Medicine/Lung Institute, UCT)',
  //   'Prof BH Ascott-Evans (Division of Diabetes and Endocrinology, University of Stellenbosch)',
  //   'Ms L Baker (Amayeza Info Centre)',
  //   'Dr C Bamford (Division of Medical Microbiology, UCT and National Health Laboratory Services)',
  //   'Dr ZA Barday (Division of Nephrology, UCT)',
  //   'Prof KI Barnes (Division of Clinical Pharmacology, UCT)', 'Dr K Bateman (Division of Neurology, UCT)',
  //   'Prof SJ Beningfield (Department of Radiation Medicine, UCT)', 'Prof M Blockman (Division of Clinical Pharmacology, UCT)',
  //   'Prof D Blom (Lipidology, UCT)', 'Prof L Blumberg (National Institute for Communicable Diseases',
  //   'Dr K Brouard (Department of Obstetrics and Gynaecology, UCT)',
  //   'Dr NA Cameron (Division of Community Health, University of Stellenbosch)',
  //   'Ms B Chisholm (Medicines Information Centre, UCT)', 'Dr MF Chughlay (Division of Clinical Pharmacology, UCT)',
  //   'Dr A Coetzee (Division of Diabetes and Endocrinology, University of Stellenbosch)', 'Prof K Cohen (Division of Clinical Pharmacology, UCT)',
  //   'Prof PJ Commerford (Division of Cardiology, UCT)', 'Dr M Conradie (Division of Diabetes and Endocrinology, University of Stellenbosch)',
  //   'Dr R Court (Division of Clinical Pharmacology, UCT)', 'Dr S Dalvie (Division of Radiation Oncology, UCT)',
  //   'Prof J Dave (Division of Endocrinology/Diabetology, UCT)', 'Prof E Decloedt (Division of Pharmacology, University of Stellenbosch)',
  //   'Dr PR de Jong (Department of Obstetrics and Gynaecology, UCT)', 'Dr R de Waal (School of Public Health and Family Medicine, UCT)',
  //   'Dr CE Du Toit (Division of Haematology, UCT)', 'Prof N du Toit (Division of Ophthalmology, UCT)',
  //   'Dr I Ebrahim (Division of Clinical Pharmacology, UCT)', 'Prof J Frean (National Institute for Communicable Diseases)',
  //   'Dr R Griesel (Division of Clinical Pharmacology, UCT)', 'Dr H Gunter (Division of Clinical Pharmacology, UCT)',
  //   'Prof E Gwyther (Division of Family Medicine, UCT)', 'Dr T Harris (Division of Otorhinolaryngology, UCT)',
  //   'Dr D Hawarden (Division of Immunology, UCT)', 'Dr SM Hawkridge (Department of Psychiatry, University of Stellenbosch)',
  //   'Prof J Heckmann (Division of Neurology, UCT)', 'Dr M Irhuma (Clinical Pharmacology, Wits)', 'Dr T Isaacs (Department of Dermatology, UCT)',
  //   'Mrs J Jones (Medicines Information Centre, UCT)', 'Prof J Joska (Department of Psychiatry and Mental Health, UCT)',
  //   'Prof IA Joubert (Department of Anaesthesia, UCT)', 'Prof AA Kalla (Division of Rheumatology, UCT)',
  //   'Prof SZ Kalula (Division of Geriatric Medicine, UCT)', 'Dr T Kredo (South African Medical Research Council)',
  //   'Prof R Lehloenya (Department of Dermatology, UCT)', 'Dr EB Lee Pan (Division of Neurology, UCT)',
  //   'Prof NS Levitt ((Division of Endocrinology/Diabetology, UCT)', 'Dr V Lomas (Helderberg Hospital, Western Cape)',
  //   'Prof G Maartens (Division of Clinical Pharmacology, UCT)', 'Dr M Makiwane (Division of Pharmacology, University of Stellenbosch)',
  //   'Prof AD Marais (Lipidology, UCT)', 'Prof H McIlleron (Division of Clinical Pharmacology, UCT)',
  //   'Dr E Meyer (Division of Otorhinolaryngology, UCT)', 'Dr N Myburgh (Department of Community Oral Health, UWC)',
  //   'Prof I Okpechi (Division of Nephrology, UCT)', 'Dr JO Olarogun (Department of Obstetrics and Gynaecology, UCT)',
  //   'Mrs V Raath (Medicines Information Centre, UCT)', 'Prof P Raubenheimer (Division of Endocrinology/Diabetology, UCT)',
  //   'Prof BL Rayner (Division of Nephrology and Hypertension, UCT)', 'Dr A Reed (Department of Anaesthesia, UCT)',
  //   'Prof HS Schaaf (Desmond Tutu TB Centre, University of Stellenbosch)', 'Prof M Senekal (Division of Human Nutrition, UCT)',
  //   'Dr S Sinha (Department of Urology, UCT)', 'Prof PZ Sinxadi (Division of Clinical Pharmacology, UCT)',
  //   'Prof MW Sonderup (Division of Hepatology, UCT)', 'Prof CWN Spearman (Division of Hepatology, UCT)',
  //   'Dr JL Straughan (Division of Clinical Pharmacology, UCT)', 'Mrs A Swart (Medicines Information Centre, UCT)',
  //   'Dr H Temmingh (Department of Psychiatry and Mental Health, UCT)', 'Emeritus Prof G Todd (Department of Medicine, UCT)',
  //   'Mr E Tommy (Medicines Information Centre, UCT)', 'Dr L Tooke (Division of Neonatology, UCT)',
  //   'Mrs A Uys (Medicines Information Centre, UCT)',
  //   'Dr DJH Veale (Poison Information Centre, Tygerberg Hospital and Red Cross Children’s Hospital)',
  //   'Dr E Verburgh (Division of Haematology, UCT)', 'Prof S Wasserman (Division of Acute General Medicine, UCT)',
  //   'Prof G Watermeyer (Division of Gastroenterology, UCT)', 'Dr J Wetter (Division of Radiation Oncology, UCT)',
  //   'Dr A Wilson (Department of Psychiatry and Mental Health, UCT)', 'Dr D Wilson (Department of Psychiatry and Mental Health, UCT)'
  // ]

  const contributors = ['Ms F Abrahams (Medicines Information Centre, UCT)',
  'Dr M Ariefdien (Medicines Information Centre, UCT)',
  'Dr ZA Barday (Division of Nephrology, UCT)',
  'Prof KI Barnes (Division of Clinical Pharmacology, UCT)',
  'Prof M Blockman (Division of Clinical Pharmacology, UCT)',
  'Prof D Blom (Division of Lipidology, UCT)',
  'Prof L Blumberg (National Institute for Communicable Diseases)',
  'Prof A Brink (Division of Medical Microbiology, UCT)',
  'Dr K Brouard (Department of Obstetrics and Gynaecology, UCT)',
  'Ms B Chisholm (Medicines Information Centre, UCT)',
  'Dr MF Chughlay (Division of Clinical Pharmacology, UCT)',
  'Dr K Cohen (Division of Clinical Pharmacology, UCT)',
  'Prof M Combrinck (Division of Geriatric Medicine, UCT)',
  'Prof PJ Commerford (Division of Cardiology, UCT)',
  'Dr M Conradie (Division of Diabetes and Endocrinology, University of Stellenbosch)',
  'Ms Riana Dippenaar (Medicines Information Centre, UCT)',
  'Prof J Dave (Division of Endocrinology, UCT)',
  'Dr S Dalvie (Division of Radiation Oncology, UCT)',
  'Ms S Davis (Amayeza Info Centre)',
  'Prof E Decloedt (Division of Pharmacology, University of Stellenbosch)',
  'Dr PR de Jong (Department of Obstetrics and Gynaecology, UCT)',
  'Dr R de Waal (School of Public Health and Family Medicine, UCT)',
  'Dr CE Du Toit (Division of Haematology, UCT)',
  'Prof N Du Toit (Division of Ophthalmology, UCT)',
  'Dr P Frankenfeld (Division of Geriatric Medicine, UCT)',
  'Prof J Frean (National Institute for Communicable Diseases)',
  'Dr T Harris (Division of Otorhinolaryngology, UCT)',
  'Prof J Heckmann (Division of Neurology, UCT)',
  'Dr J Hitzeroth (Division of Cardiology, UCT)',
  'Dr T Isaacs (Division of Dermatology, UCT)',
  'Dr A Jeftha (Oral Medicine and Periodontology, UWC)',
  'Dr E Jones (Division of Nephrology, UCT)',
  'Mrs J Jones (Medicines Information Centre, UCT)',
  'Prof J Joska (Department of Psychiatry and Mental Health, UCT)',
  'Prof IA Joubert (Department of Anaesthesia, UCT)',
  'Dr SZ Kalula (Division of Geriatric Medicine, UCT)',
  'Dr R Krause (School of Public Health and Family Medicine, UCT)',
  'Dr EB Lee Pan (Division of Neurology, UCT)',
  'Prof RJ Lehloenya (Division of Dermatology, UCT)',
  'Prof G Maartens (Division of Clinical Pharmacology, UCT)',
  'Dr N Madikizela (Division of Clinical Pharmacology, UCT)',
  'Prof AD Marais (Division of Lipidology, UCT)',
  'Prof S Moosa (Department of Radiation Medicine, UCT)',
  'Dr R Mpofu (Division of Pharmacology, UCT)',
  'Prof N Myburgh (Community Dentistry, UWC)',
  'Dr S Nightingale (Division of Neurology, UCT)',
  'Dr R Ori (Department of Psychiatry and Mental Health, UCT)',
  'Dr A Osman (Department of Obstetrics and Gynaecology, UCT)',
  'Dr M Patel (Department of Obstetrics and Gynaecology, UCT)',
  'Dr S Patel (Division of Geriatric Medicine, UCT)',
  'Prof J Peter (Allergology and Clinical Immunology, UCT)',
  'Dr S Pillay ((Division of Neonatology, UCT)',
  'Prof P Raubenheimer (Division of General Medicine, UCT)',
  'Dr A Reed (Department of Anaesthesia, UCT)',
  'Prof H Reuter (Department of Medicine, University of Stellenbosch)',
  'Prof I Ross (Division of Endocrinology/Diabetology, UCT)',
  'Prof HS Schaaf (Desmond Tutu TB Centre, University of Stellenbosch)',
  'Prof M Senekal (Division of Physiological Sciences, UCT)',
  'Dr P Sinxadi (nces, UCT)',
  'Dr P Sinxadi (Division of Clinical Pharmacology, UCT)',
  'Prof MW Sonderup (Division of Hepatology, UCT)',
  'Prof CWN Spearman (Division of Hepatology, UCT)',
  'Mrs A Swart (Medicines Information Centre, UCT)',
  'Dr G Tatz (Division of Clinical Pharmacology, UCT)',
  'Dr H Temmingh (Department of Psychiatry and Mental Health, UCT)',
  'Dr W Toet (Division of Endocrinology/Diabetology, UCT)',
  'Dr L Tooke (Division of Neonatology, UCT)',
  'Mr E Tommy (Medicines Information Centre, UCT)',
  'Mrs A Uys (Medicines Information Centre, UCT)',
  'Dr T Van Der Lecq (Division of Ophthalmology, UCT)',
  'Prof R Van Zyl-Smit (Division of Pulmonology/Lung Institute, UCT)',
  'Prof E Verburgh (Division of Haematology, UCT)',
  'Dr C Warden (Division of General Surgery, UCT)',
  'Dr S Wasserman (Department of Medicine, UCT)',
  'Dr G Watermeyer (Division of Gastroenterology, UCT)',
  'Dr N Wearne ((Division of Nephrology, UCT)',
  'Dr Wentzel Dowling (Division of Medical Microbiology, UCT)',
  'Dr J Wetter (Division of Radiation Oncology, UCT)',
  'Dr A Wilson (Department of Psychiatry and Mental Health, UCT)',
  'Dr D Wilson (Department of Psychiatry and Mental Health, UCT)'];
  const previousEditors = {
    'First edition': 'EA Conradie, JL Straughan',
    'Second edition': 'MJ Holderness, JL Straughan',
    'Third edition': 'CJ Gibbon, CR Swanepoel',
    'Fourth edition': 'CJ Gibbon, CR Swanepoel',
    'Fifth edition': 'CJ Gibbon, KI Barnes, M Blockman, PI Folb, H McIlleron, C Orrell, AH Robins',
    'Sixth edition': 'CJ Gibbon, KI Barnes, M Blockman, K Cohen, PI Folb, H McIlleron, R Onia, C Orrell, AH Robins, CR Swanepoel',
    'Seventh edition': 'CJ Gibbon, M Blockman, J Arens, KI Barnes, K Cohen, T Kredo, G Maartens, H McIlleron, R Onia, C Orrell, AH Robins, JL Straughan',
    'Eighth edition': 'CJ Gibbon, M Blockman, KI Barnes, K Cohen, R Gounden, T Kredo, G Maartens, H McIlleron, C Orrell, AH Robins, P Sinxadi, J-S van der Walt',
    'Ninth edition': 'DP Rossiter, M Blockman, KI Barnes, K Cohen, E Decloedt, R Gounden, T Kredo, G Maartens, H McIlleron, C Orrell, P Sinxadi, J-S van der Walt',
    'Tenth edition': 'DP Rossiter, M Blockman, KI Barnes, K Cohen, E Decloedt, R de Waal, T Kredo, G Maartens, H McIlleron, P Sinxadi',
    'Eleventh edition': 'DP Rossiter, M Blockman, KI Barnes, MF Chughlay, K Cohen, E Decloedt, R de Waal, R Gounden, M Irhuma, G Maartens, H McIlleron, M Pandie, P Sinxadi',
    'Twelfth edition': 'DP Rossiter, M Blockman, KI Barnes, MF Chuglay, K Cohen, E Decloedt, R de Waal, I Ebrahim, M Irhuma, G Maartens, H McIlleron, P Sinxadi',
    'Thirteenth edition': 'DP Rossiter, M Blockman, KI Barnes, MF Chuglay, K Cohen, E Decloedt, R de Waal, I Ebrahim, G Maartens, H McIlleron, P Sinxadi',
    'Fourteenth edition': 'DP Rossiter, M Blockman, KI Barnes, MF Chuglay, K Cohen, R Court, E Decloedt, R de Waal, R Mpofu, I Ebrahim, G Maartens, H McIlleron, P Sinxadi'
  }

  return (
    <>
    <div className="bg-white mx-2 md:mx-12 lg:mx-20">
      <div className="mx-auto py-6 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:pt-16 lg:pb-11">
          <div className="space-y-5 sm:space-y-4">
            <h1 className="text-3xl leading-9 font-extrabold tracking-tight text-brand-primary">
              About us
            </h1>
            <div className='font-extrabold text-brand-primary'>
              A joint initiative of the Division of Clinical Pharmacology, Faculty of Health Sciences, University of Cape Town and the South African Medical Association.
            </div>
            <div className='font-extrabold text-brand-primary'>
              Based within the Division of Clinical Pharmacology at the University of Cape Town, the SAMF is edited by Tashni Naidu , with Marc Blockman as managing editor and a number of co-editors.
            </div>
          </div>

      </div>

      <div className="mx-auto px-4 max-w-screen-xl sm:px-6 lg:px-8 mb-15">
        <div className='bg-brand-primary rounded-full avatar-background flex mb-5'>
          <img className='editor-avatar' alt='Tashni Naidu' src={ tashni_avatar }></img>
          <div className='text-white mx-4 editor-description'>
            <div className='font-bold mb-4'>Editor</div>
            <div>Tashni Naidu, BPharm MPharm</div>
          </div>
        </div>

        <div className='bg-brand-primary rounded-full avatar-background flex mb-8'>
          <img className='editor-avatar' alt='Marc Blockman' src={ marc_avatar }></img>
          <div className='text-white mx-4 editor-description'>
            <div className='font-bold mb-4'>Managing Editor</div>
            <div>Marc Blockman, MBChB BPharm PG Dip Int Res Ethics MMed (Clin Pharmacol)</div>
          </div>
        </div>

        <div className='text-gray-700 mb-6'>
          We wish to acknowledge and thank:
          <ul className='list-disc ml-6'>
            <li>our colleagues in the Medicines Information Centre and Division of Clinical Pharmacology, UCT;</li>
            <li>all those contributing to previous editions;</li>
            <li>the pharmaceutical industry (for product information)</li>
          </ul>
        </div>

        <div className='mb-7'>
          <div className='font-bold text-gray-700 text-lg mb-1'>Co-editors</div>
          {coeditors.map((item, i) => (
          <div className='py-1'>
            <div className='text-gray-600 text-base'>
              {item}
            </div>
          </div>
            ))}
        </div>

        <div className='mb-7'>
          <div className='font-bold text-gray-700 text-lg mb-1'>Contributors and consultants (15<sup>th</sup> ed)</div>
          <div className='flex'>
            <div className='pr-10 w-1/2 border-r-2 border-gray-500'>
              {contributors.slice(0, contributors.length/2).map((item, i) => (
                <div className='py-1'>
                  <div className='text-gray-600 text-base'>
                    {item}
                  </div>
                </div>
              ))}
            </div>
            <div className='pl-10 w-1/2'>
              {contributors.slice(contributors.length/2, contributors.length).map((item, i) => (
                <div className='py-1'>
                  <div className='text-gray-600 text-base'>
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


        <div className='mb-7'>
          <div className='font-bold text-gray-700 text-lg mb-1'>Previous editors and co-editors</div>
          <div className='pr-10'>
            {Object.keys(previousEditors).map((key, i) => (
              <div className='py-1'>
                <div className='text-black text-base font-bold inline pr-2'>
                  {key}:
                </div>
                <div className='inline text-base'>{previousEditors[key]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default AboutUs;
