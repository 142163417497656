import React from 'react';
import { createMarkup} from '../../utils/utils';

const OtherContentBody = ({ content }) => {

  return (
    <div dangerouslySetInnerHTML={createMarkup(content.body)} className="server-content"/> 
  )
}

export default OtherContentBody;