// Predicates
export const isFunction = (fn) =>
  fn && {}.toString.call(fn) === "[object Function]";

export const isPlainObject = (o) =>
  o && Object.prototype.toString.call(o) === "[object Object]";

export const isArray = (x) => Array.isArray(x);

export const isNil = (x) => typeof x === "undefined" || x === null;

export const isEmpty = (x) => {
  if (isArray(x)) {
    return !x.length;
  }

  if (isPlainObject(x)) {
    return !Object.entries(x).length;
  }

  return x === "";
}; // x === '' || x === [] || x === {};

export const hasValue = (x) => !isNil(x) && !isEmpty(x);

// Helpers
export const pipe = (first, ...fns) => (...args) =>
  fns.length === 0 ? first(...args) : pipe(...fns)(first(...args));

export const map = (fn) => (x) => {
  if (isFunction(x.map)) {
    return x.map(fn);
  }

  if (isPlainObject(x)) {
    const n = {};

    /* eslint-disable */
    for (let key in x) {
      n[key] = fn(x[key], key, x);
    }
    /* eslint-enable */

    return n;
  }

  return new TypeError("Provided object is not mapable!");
};

export const filter = (pred) => (x) => {
  if (isFunction(x.filter)) {
    return x.filter(pred);
  }

  if (isPlainObject(x)) {
    const n = {};

    for (const key in x) {
      if (pred(x[key])) {
        n[key] = x[key];
      }
    }

    return n;
  }

  return new TypeError("Provided object is not filterable!");
};

export const reduce = (fn, acc) => (x) => {
  if (isArray(x) && isFunction(x.reduce)) {
    return x.reduce(fn, acc);
  }

  return new TypeError("Provided object is not reduceable!");
};

export const tap = (fn) => (x) => {
  fn(x);

  return x;
};

export const head = (x) => {
  if (isArray(x) && hasValue(x)) {
    return x[0];
  }

  return null;
};

/* eslint-disable no-console */
export const log = (...args) => (v) => {
  console.log(...args, v);
};
/* eslitn-enable */

export const constant = (x) => () => x;
export const identity = (x) => x;
export const orValue = (a, b) => (!!a ? a : b);

// then : (a -> b) -> Promise -> Promise
export const then = (fn) => (P) => {
  if (isFunction(P.then)) {
    return P.then(fn);
  }

  return new TypeError("Provided object is not a Promise");
};

// thenCatch :: (a -> b) -> (c -> d) -> Promise -> Promise
export const thenCatch = (onSuccess, onError = identity) => (P) => {
  if (isFunction(P.then)) {
    return P.then(onSuccess).catch(onError);
  }

  return new TypeError("Provided object is not a Promise");
};

export const pathOr = (fallback) => (path) => (data) => {
  if (isNil(data)) {
    return fallback;
  }

  return path.reduce((acc, next) => {
    if (!isNil(acc) && !isNil(acc[next])) {
      return acc[next];
    }

    return fallback;
  }, data);
};

export const noop = () => {};

export const sort = (fn) => (arr) => {
  if (!isFunction(fn)) {
    return arr.sort();
  }

  return arr.sort(fn);
};

export const replaceInAppLinks = (str) =>
  str ? str.replace(/(in-app-description|in-app-list):\/\//gi, "/book/all/") : '';

export const createMarkup = pipe(replaceInAppLinks, (x) => ({ __html: x }));

const codeComparator = (s1, s2) => {
  if (/\d/g.test(s1.code) === false)
    return -1
  if (/\d/g.test(s2.code) === false)
    return 1
  return s1.code > s2.code ? 1 : -1;
};

const titleComparator = (s1, s2) => {
  return s1.title > s2.title ? 1 : -1;
};

const getItemChildren = (item, currentPath) => {
  let children = [];

  if (hasValue(item.sections)) {
    if(item.code === 'Prescribing'){
      sort(titleComparator)(Object.values(item.sections)).forEach((v) =>
        children.push(itemToNode(v, item.contentId, currentPath))
      );
    } else {
      sort(codeComparator)(Object.values(item.sections)).forEach((v) =>
        children.push(itemToNode(v, item.contentId, currentPath))
      );
    }
  }
  if (hasValue(item.supercategories)) {
    Object.values(item.supercategories).forEach((v) =>
      children.push(itemToNode(v, item.contentId, currentPath))
    );
  }
  if (hasValue(item.categories)) {
    Object.values(item.categories).forEach((v) =>
      children.push(itemToNode(v, item.contentId, currentPath))
    );
  }
  if (hasValue(item.drugs)) {
    Object.values(item.drugs).forEach((v) =>
      children.push(itemToNode(v, item.contentId, currentPath))
    );
  }
  return children;
};

const getItemType = (item) =>{
  let type = 'Chapter'
  if(item.section)
    type = 'Section'
  else if (item.supercategory)
    type = 'Section'
  else if (item.category)
    type = 'Category'
  else if (item.extraData)
    type = 'Drug'
  return type;
}

const itemToNode = (item, parentId, path = []) => {
  const currentPath = path.concat([item.uniqueRef])
  return {
    id: item.contentId,
    parentId: parentId,
    ref: item.uniqueRef,
    name: item.htmlTitle,
    title: item.title
      ? item.code && item.chapter
        ? `${item.code}. ${item.title}`
        : item.title
      : item.name,
    type: getItemType(item),
    children: getItemChildren(item, currentPath),
    data: item,
    path: currentPath,
    order: item.order
  };
};

export const chapterToTree = (chapter) => itemToNode(chapter, null, []);
export const favToNode = (favId) => (fav) => {
  return {
    id: fav.contentId,
    favId: favId,
    parentId: null,
    ref: fav.uniqueRef,
    name: fav.htmlTitle,
    children: [],
    data: fav,
    path: []
  };
};

export const findInTree = (prop, val) => (tree) => {
  if (tree[prop] === val) {
    return tree;
  }
  return tree.children
    ? tree.children.map(findInTree(prop, val)).reduce(orValue, null)
    : null;
};

export const trim = (str) => str.trim()

export const hasKey = key => obj => obj.hasOwnProperty(key)
