import React, { createContext, useEffect, useState } from "react";
import { firestore } from "../services/firebase";
import { chapterToTree, sort } from "../utils/utils";

const sortByOrder = sort((a, b) => a.order > b.order ? 1 : -1)

export const ChaptersContext = createContext({ chapters: [] });
export const ChaptersProvider = ({ children }) => {
  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    const chaptersRef = firestore.collection("deployed-chapters");
    const unsubscribe = chaptersRef.onSnapshot((qs) =>
      qs.forEach((doc) =>
        setChapters((chs) => chs.concat([chapterToTree(doc.data())]))
      )
    );

    return unsubscribe;
  }, [setChapters]);

  return (
    <ChaptersContext.Provider value={sortByOrder(chapters)}>
      {children}
    </ChaptersContext.Provider>
  );
};
