import "./App.css";

import React, { useContext } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import About from "./screens/about/about";
import AboutUs from "./screens/about/about_us";
import Book from "./screens/book/book";
import BrowserWarning from "./components/browser-detection/browser-detection";
import BuySAMF from "./screens/buy/buy_samf";
import Ciprofloxacin from "./screens/drug_sample/ciprofloxacin";
import Contact from "./screens/contact/contact";
import CookieModal from "./components/cookie-banner/cookie-banner";
import FAQ from "./screens/about/faq";
import ForgotPassword from "./screens/forgot_password/forgot_password";
import HomePage from "./screens/home/home-page";
import Login from "./screens/login/login";
import MasterLayout from "./components/layouts/master";
import PrivacyModal from "./components/privacy-modal/privacy-modal";
import Profile from "./screens/profile/profile";
import ReactGA from "react-ga";
import StaticPage from "./screens/static/static-page";
import { UserContext } from "./contexts/user-context";
import { UserProvider } from "./contexts/user-context";
import DeleteAccount from "./screens/delete_account/delete-account";

// import Home from "./screens/home/home";

const initGA = () => {
  ReactGA.initialize("UA-154682477-1");
  ReactGA.pageview("/home");
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, authState } = useContext(UserContext);

  if (!user && authState !== 1) {
    // just hold off until firebase can determine if it can auth the user
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/about">
        <About />
      </Route>
      <Route path="/privacy">
        <StaticPage />
      </Route>
      <Route path="/tos">
        <StaticPage />
      </Route>
      <Route path="/home3">
        <HomePage />
      </Route>
      <Route path="/cookie-policy">
        <StaticPage />
      </Route>
      <Route path="/medicine-info-services">
        <StaticPage />
      </Route>
        <Route path="/delete-account" render={(props) => <DeleteAccount {...props} />} />


      <Route path="/faq">
        <FAQ />
      </Route>
      <Route path="/abbreviations">
        <StaticPage />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/buy-samf">
        <BuySAMF />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/about-us">
        <AboutUs />
      </Route>
      <Route path="/ciprofloxacin">
        <Ciprofloxacin />
      </Route>
      <PrivateRoute path="/book" component={Book} />
      <PrivateRoute path="/profile" component={Profile} />
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  );
};

function App() {
  initGA();

  return (
    <Router>
      <UserProvider>
        <BrowserWarning />
        <MasterLayout>
          <Routes />
        </MasterLayout>
      </UserProvider>
    </Router>
  );
}

export default App;
