const config = {
  algolia: {
    appId: 'G4ETVVNTU3',
    apiKey: '24b7bfc7c8799ad227a6dde255c1021a',
  },
  firebase: {
    apiKey: "AIzaSyAtX3PldntJSJN2D-TjsxG3ZlwuAxZrg6c",
    authDomain: "samf-test.firebaseapp.com",
    databaseURL: "https://samf-test.firebaseio.com",
    projectId: "samf-test",
    storageBucket: "samf-test.appspot.com",
    messagingSenderId: "26647683437",
    appId: "1:26647683437:web:3fcf88c7190a9fa928ba64",
    measurementId: "G-DXK6ZZB2N7"
  },
  baseUrl: "https://europe-west2-samf-test.cloudfunctions.net/api"
}

export default config
