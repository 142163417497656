import React from "react";
import 'react-tabs/style/react-tabs.css';
import medicine from "../../../assets/images/onboarding/what_to_find/medicine.png"
import clinical_notes from "../../../assets/images/onboarding/what_to_find/clinical_notes.png"
import prescribing_special_populations from "../../../assets/images/onboarding/what_to_find/prescribing.png"
import poisoning from "../../../assets/images/onboarding/what_to_find/poisoning.png"
import OnboardingItem from "./onboarding-item";

const WhatInfoToFind = () => {

  return (
    <div>
          <OnboardingItem
            itemTitle="1. <b>Medicine monographs</b>. For each medicine you can find information on cautions, contraindications and
              adverse effects, adult or paediatric dosing, preparations, pharmacokinetics, special prescriber's points etc."
          >
            <img src={ medicine } alt="Medicine"/>
          </OnboardingItem>

          <OnboardingItem
            itemTitle='2. <b>Clinical notes</b> including the management of diseases and conditions.'
          >
            <img src={ clinical_notes } alt="Clinical notes"/>
          </OnboardingItem>

          <OnboardingItem
            itemTitle='3. Information on <b>prescribing in special populations</b>.'
          >
            <img src={ prescribing_special_populations } alt="Prescribing in special populations"/>
          </OnboardingItem>

          <OnboardingItem
            itemTitle='4. <b>Emergency treatment of poisoning</b>.'
          >
            <img src={ poisoning } alt="Emergency treatment of poisoning"/>
          </OnboardingItem>
      </div>
  );
};

export default WhatInfoToFind;

