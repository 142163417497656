import React from "react";
import { createMarkup } from "../../utils/utils";
import FavMark from '../favs/fav-button'

const DrugHeader = ({ content, isDemo = false }) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex-1">
        <h3 className="text-lg leading-6 font-medium text-white">
          <div dangerouslySetInnerHTML={createMarkup(content.name)} />
        </h3>
        <h5 className="text-brand-secondary py-2">
          <p>
            {content.extraData.INN && "[INN] "}
            {content.extraData.ATCCode}
          </p>
        </h5>
        {content.extraData.ScheduleCode && (
          <h5 className="text-green-400">{content.extraData.ScheduleCode}</h5>
        )}
        {content.extraData.EML && (
          <p className="text-white text-sm">
            <span className="font-bold">SA EML</span>
            {`: ${content.extraData.EML}`}
          </p>
        )}
        {content.extraData.routeOfAdministration && (
          <p className="text-white text-sm">
            <span className="font-bold">Route of administration</span>
            {`: ${content.extraData.routeOfAdministration.join(", ")}`}
          </p>
        )}
        {content.extraData.specialPopulation && (
          <p className="text-white text-sm">
            <span className="font-bold">Special populations</span>
            {`: ${content.extraData.specialPopulation.join(", ")}`}
          </p>
        )}
        {content.extraData.synonyms && (
          <p className="text-white text-sm">
            <span className="font-bold">Synonyms</span>
            {`: ${content.extraData.synonyms.join(", ")}`}
          </p>
        )}
      </div>
      <div>
        {isDemo ? <div></div> : <FavMark item={ content }/>}
      </div>
    </div>
  );
};

export default DrugHeader;

