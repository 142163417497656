import "./cookie-banner.css";

import React, { useState } from "react";

import { Link } from "react-router-dom";

const CookieBanner = () => {
  let accepted = localStorage.getItem("accepted_terms");
  const [display, setDisplay] = useState(!accepted);

  const onOkayClicked = () => {
    localStorage.setItem("accepted_terms", true);
    setDisplay(false);
  };

  return display ? (
    <div className="cookie-banner-container">
      <div className="cookie-banner-text">
        <b>We use cookies</b>
        <p>
          Cookies help us deliver the best experience on our website. By using
          our website, you agree to the use of cookies.{" "}
          <Link to="/cookie-policy">Find out how we use cookies</Link>.
          {/* By using this website, you are agreeing to our{" "}
          <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>{" "}
          and{" "}
          <a href="/tos" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
          . */}
        </p>
      </div>
      <div className="accept-terms-button" onClick={() => onOkayClicked()}>
        <p>Agree</p>
      </div>
    </div>
  ) : null;
};

export default CookieBanner;
