import React, { useContext } from "react";
import { FavoritesContext } from "../../../contexts/favorites-context";

import TreeNode from "../../../components/tree/tree";
import CurrentContent from "../common/current-content";
import BookLayout from "../common/book-layout";

const Favs = () => {
  const favorites = useContext(FavoritesContext);

  return (
    <BookLayout
      onLeftPane={() => (
        <>
          {favorites.map((c, i) => (
            <TreeNode area='favourites' node={c} key={i} />
          ))}
        </>
      )}
      onRightPane={(handleScroll) => <CurrentContent handleScroll={ handleScroll } />}
    />
  );
};

export default Favs;

