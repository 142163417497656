import React, { useContext } from "react";
import { ChaptersContext } from "../../../contexts/chapters-context";
import TreeNode from "../../../components/tree/tree";
import CurrentContent from "../common/current-content";
import BookLayout from "../common/book-layout";

const All = () => {
  const chapters = useContext(ChaptersContext);

  return (
    <BookLayout
      onLeftPane={() => (
        <>
          {chapters.map((c, i) => (
            <TreeNode node={c} key={i} />
          ))}
        </>
      )}
      onRightPane={(handleScroll) => <CurrentContent handleScroll={ handleScroll }/>}
    />
  );
};

export default All;

