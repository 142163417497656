import React, { createContext, useEffect, useState } from "react";
import { auth, firestore } from "../services/firebase";
import { useHistory } from "react-router-dom";
import { hasValue } from '../utils/utils'
import axios from '../services/axios'
import moment from 'moment';


const getProviderData = userAuth => {
  if(hasValue(userAuth.providerData)) {
    const provider = userAuth.providerData[0]

    if(provider.providerId === 'google.com') {
      return {
        avatarUrl: provider.photoURL,
        displayName: provider.displayName,
        email: provider.email,
        provider: 'google'
      }
    }

    return
  }

  return {
    avatarUrl: userAuth.photoURL,
    displayName: userAuth.displayName,
    email: userAuth.email
  }
}

// AuthState Loading - 0, Success - 1, Failed - 2,

export const UserContext = createContext({ user: null, handleSignout: () => {} });
export const UserProvider = ({ children }) => {
  const [authState, setAuthState] = useState(0)
  const [user, setUser] = useState(null);
  const history = useHistory();

  const handleSignout = () => {

    // const userAgent = navigator.userAgent
    let browserId = localStorage.getItem('SAMFBrowserId');

    let query = firestore.collection("devices")
      .where("userId", "==", user.uid)
      // .where("userAgent", "==", userAgent)
      .where("status", "==", "ACTIVE")

    if (browserId !== null && browserId !== undefined)
      query = query.where("browserId", "==", browserId)

    query.get().then(snapshot => {
        if(snapshot.docs.length > 0){
          const currentActiveDevice = Object.assign({}, {id: snapshot.docs[0].id}, snapshot.docs[0].data())
          firestore.collection("devices").doc(currentActiveDevice.id)
            .update({status: "INACTIVE"})
            .then( resp =>{
              auth.signOut().then(() => {
                setUser(null);
                history.push("/home");
              });
            })
        } else {
          auth.signOut().then(() => {
            setUser(null);
            history.push("/home");
          });
        }

      })

  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {

        // console.log("user is logged in", userAuth);
        let userData = {
          uid: userAuth.uid,
          ...getProviderData(userAuth)
        }

        const userRef = firestore.collection('users').doc(userAuth.uid)

        try {
          userRef.get()
            .then(async doc => {
              if (doc.exists) {
                const userAgent = navigator.userAgent
                let browserId = localStorage.getItem('SAMFBrowserId');
                if(browserId === undefined || browserId === null){
                  browserId = Math.random().toString(36).substr(2, 12);
                  localStorage.setItem('SAMFBrowserId', browserId);
                }

                const verifySubscriptionPayload = {userAgent: userAgent, browserId: browserId}

                auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                  axios.defaults.headers.common['Authorization'] = "Bearer " + idToken;
                  axios.post('/verify-subscription-web', verifySubscriptionPayload).then(res => {

                    const userProfile = doc.data();

                    setUser({
                      ...userData,
                      displayName: `${userProfile.firstName ? userProfile.firstName : ''} ${userProfile.lastName ? userProfile.lastName : ''}`,
                      ...userProfile
                    })
                    setAuthState(1);
                    try{
                    firestore.collection("purchases")
                      .where("userId", "==", userAuth.uid)
                      .where("status", "==", "ACTIVE")
                      .onSnapshot((querySnapshot) => {
                        if (querySnapshot.size === 0) {
                          // It means the subscription has expired

                          const userAgent = navigator.userAgent
                          let browserId = localStorage.getItem('SAMFBrowserId')

                          let query = firestore.collection("devices")
                            .where("userId", "==", userAuth.uid)
                            .where("userAgent", "==", userAgent)
                            .where("status", "==", "ACTIVE")


                          if (browserId !== null && browserId !== undefined)
                            query = query.where("browserId", "==", browserId)

                          query.get()
                            .then(snapshot => {
                              if (snapshot.docs.length > 0) {
                                const currentActiveDevice = Object.assign({}, {id: snapshot.docs[0].id}, snapshot.docs[0].data())
                                firestore.collection("devices").doc(currentActiveDevice.id)
                                  .update({status: "INACTIVE"})
                                  .then(resp => {
                                    auth.signOut().then(() => {
                                      setUser(null);
                                      history.push("/home");
                                      alert("Your subscription has expired! If you wish to continue having access to the SAMF content, please renew your subscription.")
                                    });
                                  })
                              }

                            })
                        } else {
                          firestore.collection("revoked-access")
                            .where("userId", "==", userAuth.uid)
                            .onSnapshot((querySnapshot) => {
                              if (querySnapshot.docs.length > 0) {
                                const revokeData = querySnapshot.docs[0].data()
                                const revokedAt = moment.unix(revokeData.revokedAt['seconds'])

                                let lastSignedIn = auth.currentUser.metadata.lastSignInTime
                                lastSignedIn = moment(new Date(lastSignedIn))

                                if (revokedAt.isAfter(lastSignedIn)) {
                                  // Need to logout
                                  auth.signOut().then(() => {
                                    setUser(null);
                                    history.push("/home");
                                  });
                                }
                              }
                            });
                        }
                      });
                    } catch(e){
                      console.log('Caught error', e)
                    }
                  }).catch(function (error) {
                    console.log("Error on verifying user subscription", error)

                    let errorMessage = ''
                    if(error!= null && error.response != null && error.response.data != null){
                      errorMessage = error.response.data
                    }
                    const isDeviceLimitError = errorMessage === 'The limit number of active devices has been reached!'


                    if(!isDeviceLimitError){
                      auth.signOut().then(() => { setUser(null) });
                    } else {
                      setUser({
                        ...userData,
                        subscriptionError: true
                      })
                    }
                    setAuthState(2)

                  })
                });
                return;
              }

              // If the user is not found in the database we don't allow 3rd party auth;
              // setUser(userData);
              // setAuthState(1);
            })
            .catch(error => {
              auth.signOut().then(() => { setUser(null) });
              setAuthState(2);
            })
        } catch (error) {
          console.log("Caught error", error);
        }
      }

      setUser(null);
      setAuthState(2)
    });

    return unsubscribe;
  }, []);
  /* eslint-enable */

  return (
    <UserContext.Provider value={{ user, signOut: handleSignout, authState }}>
      {children}
    </UserContext.Provider>
  );
};
