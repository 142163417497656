import React, { createContext, useEffect, useState, useContext } from "react";
import { firestore } from "../services/firebase";
import {UserContext} from './user-context';

import { favToNode } from '../utils/utils';

export const FavoritesContext = createContext({ favorites: [] });
export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const {user} = useContext(UserContext)

  useEffect(() => {
    if(user){
      const favoritesRef = firestore.collection('favorites').where('userId', '==', user.uid)
      const unsubscribe = favoritesRef.onSnapshot(
        qs => {
          let temp = []
          qs.forEach(doc => { temp.push( favToNode(doc.id)(doc.data())) })

          setFavorites(temp)
        }
      )

      return unsubscribe;
  }}, [setFavorites, user])

  return (
    <FavoritesContext.Provider value={favorites}>
      {children}
    </FavoritesContext.Provider>
  );
} 
