import React, { useRef } from 'react'
import { isFunction } from '../../../utils/utils'

const BookLayout = ({ onLeftPane, onRightPane }) => {
  const rightRef = useRef(null)

  const scrollRight = () => { 
    if(isFunction(rightRef.current.scrollTo)) { 
      rightRef.current.scrollTo(0, 0)
    } else { 
      rightRef.current.scrollTop = 0;
    }
  }

  return (
    <div className="h-content flex overflow-hidden bg-white">
    {/*<!-- Off-canvas menu for mobile -->*/}
    <div className="md:hidden">
      <div className="fixed inset-0 flex z-40">
        {/*<!--
          Off-canvas menu overlay, show/hide based on off-canvas menu state.
  
          Entering: "transition-opacity ease-linear duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "transition-opacity ease-linear duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->*/}
        <div className="fixed inset-0">
          <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
        {/*<!--
          Off-canvas menu, show/hide based on off-canvas menu state.
  
          Entering: "transition ease-in-out duration-300 transform"
            From: "-translate-x-full"
            To: "translate-x-0"
          Leaving: "transition ease-in-out duration-300 transform"
            From: "translate-x-0"
            To: "-translate-x-full"
        -->*/}
        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
          <div className="absolute top-0 right-0 -mr-14 p-1">
            <button className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
              <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div className="flex-shrink-0 flex items-center px-4">
              
            </div>
            <nav className="mt-5 px-2 space-y-1">
            { onLeftPane() }
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            <div className="flex-shrink-0 group block focus:outline-none">
              <div className="flex items-center">
                {/* bottom box */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 w-14">
          {/*<!-- Force sidebar to shrink to fit close icon -->*/}
        </div>
      </div>
    </div>
  
    {/*<!-- Static sidebar for desktop -->*/}
    <div className="hidden md:flex md:flex-col w-64 overflow-hidden md:flex-shrink-0">
      <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <div className="pt-2 pb-6 md:py-6 border-r border-gray-200">
          <nav className="mt-0 flex-1 px-2 bg-white space-y-1 ">
            {/*<!-- Replace with your content -->*/}
            { onLeftPane() }
            {/*<!-- /End replace -->*/}
          </nav>
        </div>
      </div>
    </div> 
    <div className="flex flex-col w-0 flex-1 overflow-hidden">
      <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150" aria-label="Open sidebar">
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      <main ref={ rightRef } className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <div className="pt-2 pb-6 md:py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/*<!-- Replace with your content -->*/}
            { onRightPane(scrollRight) }
            {/*<!-- /End replace -->*/}
          </div>
        </div>
      </main>
    </div>
  </div>
  
  )
}

export default BookLayout;
