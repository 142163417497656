import React from "react";
import './delete-account-modal.css'

const DeleteAccountModal = ({ onClose, onDelete }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>Are you sure you want to delete your account?</p>
        <div className="button-container">
          <button className="delete-button" onClick={onDelete}>
            Yes
          </button>
          <button className="cancel-button" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccountModal;
