import React, { useState, useContext, useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";
import { 
  createMarkup, 
  isEmpty, 
  isNil,
  pathOr, 
  pipe, 
  reduce 
} from "../../utils/utils";
import { BookNavigationContext } from "../../contexts/book-navigation-context";

const StatusIndicator = ({ isOpen, hidden }) => {
  return (
    <div className={ `${hidden ? "invisible" : ""} w-5 h-6 flex justify-center items-center` }>
      {!isOpen ? (
        <svg
          className="h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          className="h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </div>
  );
};

const hasToShowCode = (node) => 
  node.data.code 
    && node.parentId === null 
    && !['Prescribing', 'Poisoning'].includes(node.data.code)

const isInPathOf = selected => node => {
  const matchProp = 'ref'
  const inPath = pipe(
    reduce((acc, next) => next === node[matchProp] || acc, false)
  )

  return inPath(selected.path);
}

const bringInView = (el, offset = 0) => { 
  const top = el.getBoundingClientRect().top;
  const isVisible = top + offset >= 0 && top - offset <= window.innerHeight;

  if(!isVisible){ 
    el.scrollIntoView({ behaviour: 'smooth', block: 'center' })
  }
}

const TreeNode = ({ node, area = 'all'}) => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { selectedItem } = useContext(BookNavigationContext);

  const hasChildren = !isEmpty(node.children);
  const isChapter = isNil(node.parentId);
  const isCurrent =
    selectedItem && node.id === pathOr(null)(["id"])(selectedItem);

  const handleClick = () => {
    if (hasChildren && isOpen) {
      // setIsOpen(!isOpen);
    }

    // console.log("sel", node);
    history.push(`/book/${area}/${node.ref}`);
  };
  useEffect(() => {

    if(!isOpen && selectedItem && isInPathOf(selectedItem)(node)) { 
      setIsOpen(true)
      bringInView(ref.current)
    }

    if(isOpen && selectedItem && isInPathOf(selectedItem)(node)) { 
      // bringInView(ref.current)
    }

    if(isOpen && selectedItem && !isInPathOf(selectedItem)(node)){ 
      setIsOpen(false)
    }

  }, [isOpen, node, selectedItem])

  return (
    <div className={`pl-${isChapter ? "0" : "5"}`}>
      <div
        ref={ ref } 
        onClick={handleClick}
        className={`${
          isCurrent ? "bg-gray-200" : "bg-white "
        } mb-1 text-gray-900 hover:text-gray-900 hover:bg-gray-100 flex p-1 text-sm leading-5 font-medium rounded-md focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 cursor-pointer`}
      >
        {hasChildren ? <StatusIndicator isOpen={isOpen} hidden={ false }/> : <StatusIndicator isOpen={isOpen} hidden={ true } />  }
        <div className="flex flex-row">
          { hasToShowCode(node) 
            ? ( 
              <span 
                className="pr-1 text-brand-secondary font-bold">
                { node.data.code }
              </span> 
            ) 
            : null 
          }
          <div 
            className={ `${ isChapter ? "font-bold text-brand-primary" : "text-gray-600" } ${ !hasChildren ? " text-gray-700" : "" }` } 
            dangerouslySetInnerHTML={createMarkup(node.name)} />
        </div>
      </div>
      {hasChildren && isOpen && (
        <div>
          {node.children.map((ch) => (
            <TreeNode area={ area } node={ch} key={ch.id} />
          ))} 
        </div>
      )}
    </div>
  );
};

export default TreeNode;
