// import React from 'react'
import firebase, { auth } from '../services/firebase'
import { firestore } from '../services/firebase'
import axios from "../services/axios";

const signInHandlers = {
  'google': (onError, onSuccess) => {
    let provider = new firebase.auth.GoogleAuthProvider()

    auth.signInWithPopup(provider)
      .then(async res => {
        const userId = res.user.uid;

        const subscriptionStatus = await checkSubscription();
        if(!subscriptionStatus.success) {
          if(subscriptionStatus.deviceLimitError === undefined || subscriptionStatus.deviceLimitError === false)
            auth.signOut().then(() => {});
          onError(subscriptionStatus.errorMessage);
        } else if(res.credential) {

          firestore.collection("users").doc(userId).get().then((doc) => {
            if(doc.exists) {
              onSuccess({
                googleAccessToken: res.credential.token,
                user: res.user
              })
            } else {
              auth.signOut().then(() => {});
              onError('Authentication failed. This account was not registered on the mobile app!')
            }
          })

        }
      })
      .catch(error => {
        console.error(error)
        onError('Google authentication failed')
      })
  },
  'facebook': (onError, onSuccess) => {
    let provider = new firebase.auth.FacebookAuthProvider();

    auth.signInWithPopup(provider)
      .then(async res => {
        const userId = res.user.uid;

        const subscriptionStatus = await checkSubscription();
        if(!subscriptionStatus.success) {
          if(subscriptionStatus.deviceLimitError === undefined || subscriptionStatus.deviceLimitError === false)
            auth.signOut().then(() => {});
          onError(subscriptionStatus.errorMessage);
        } else if(res.credential) {
          firestore.collection("users").doc(userId).get().then((doc) => {
            if(doc.exists) {
              onSuccess({
                facebookAccessToken: res.credential.accessToken,
                user: res.user
              })
            } else {
              auth.signOut().then(() => {});
              onError('Authentication failed. This account was not registered on the mobile app!')
            }
          })

        }
      })
      .catch(error => {
        console.error(error)
        onError('Facebook authentication failed')
      })

  },
  'apple': (onError, onSuccess) => {
    let provider = new firebase.auth.OAuthProvider('apple.com');

    auth.signInWithPopup(provider)
      .then(async res => {
        const userId = res.user.uid;

        const subscriptionStatus = await checkSubscription();
        if(!subscriptionStatus.success) {
          if(subscriptionStatus.deviceLimitError === undefined || subscriptionStatus.deviceLimitError === false)
            auth.signOut().then(() => {});
          onError(subscriptionStatus.errorMessage);
        } else if(res.credential) {
          firestore.collection("users").doc(userId).get().then((doc) => {
            if(doc.exists) {
              onSuccess({
                appleAccessToken: res.credential.accessToken,
                appleIdToken: res.credential.idToken,
                user: res.user
              })
            } else {
              auth.signOut().then(() => {});
              onError('Authentication failed. This account was not registered on the mobile app!')
            }
          })

        }
      })
      .catch(error => {
        console.error(error)
        onError('Apple authentication failed')
      })
  }
}


async function checkSubscription(){
  const userAgent = navigator.userAgent
  let browserId = localStorage.getItem('SAMFBrowserId');
  if(browserId === undefined || browserId === null){
    browserId = Math.random().toString(36).substr(2, 12);
    localStorage.setItem('SAMFBrowserId', browserId);
  }

  const verifySubscriptionPayload = {userAgent: userAgent, browserId: browserId}

  const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true)

  axios.defaults.headers.common['Authorization'] = "Bearer " + idToken;
  try {
    await axios.post('/verify-subscription-web', verifySubscriptionPayload);
    return {success: true}
  } catch (e){
    let errorMessage = 'Authentication failed. No active subscription found for this account!'
    if(e!= null && e.response != null && e.response.data != null){
      errorMessage = e.response.data
    }
    const isDeviceLimitError = errorMessage === 'The limit number of active devices has been reached!'
    return {success: false, errorMessage: errorMessage, deviceLimitError: isDeviceLimitError}
  }
}

const useFirebaseLogin = (onError, onSuccess) => {
  const signIn = (options) => {
    if(options.provider) {
      signInHandlers[options.provider](onError, onSuccess);

      return;
    }

    const { email, password } = options;
    auth.signInWithEmailAndPassword(email, password)
      .then( async response => {
        const subscriptionStatus = await checkSubscription();
        if(!subscriptionStatus.success){
          if(subscriptionStatus.deviceLimitError === undefined || subscriptionStatus.deviceLimitError === false)
            auth.signOut().then(() => {});
          onError(subscriptionStatus.errorMessage);
        } else {
          // listenSubscription(response.user.uid)
        }
      })
      .catch(error => {
        console.error("Error signing in with password and email", error);
        onError("Error signing in with password and email!");
    });
  }
  return signIn;
}

export default useFirebaseLogin;
