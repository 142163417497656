import { useEffect, useState } from 'react';

const useCheckMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 768);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setIsMobile(() => window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};

export default useCheckMobileScreen;
