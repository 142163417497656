import React, {useState, useRef} from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom'
import { InstantSearch, connectAutoComplete,connectHighlight } from 'react-instantsearch-dom';
import searchClient from '../../services/algolia';
import { hasValue, isFunction } from '../../utils/utils'

const Autocomplete = ({ hits, currentRefinement, refine, delay }) => {
  const [cursor, setCursor] = useState(0)
  const [searchValue, setSearchValue] = useState(currentRefinement)
  const container = useRef(null)
  const refsArray = useRef([])
  const timerId = useRef(null)
  const history = useHistory()
  const onChangeDebounced = event => {
    const value = event.currentTarget.value;

    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      ReactGA.event({
        category: 'Search',
        action: 'Search',
        label: value
      });
      refine(value)
    }, delay);

    setSearchValue(value)
    // keep cursor and scroll position top while typing
    container.current.scrollTo(0, 0)
    if(isFunction(container.current.scrollTo)) {
      container.current.scrollTo(0, 0)
    } else {
      container.current.scrollTop = 0;
    }
    setCursor(0)
  };

  const scrollContainerBy = (distance) => {
    container.current.scrollBy({ top: distance, behavior: 'smooth' })
  }

  const handleScroll = (item, direction) => {
    const { clientHeight } = item

    if(direction === 'up') {
      scrollContainerBy(clientHeight)
    }
    if(direction === 'down') {
      scrollContainerBy(-clientHeight)
    }
  }

  const handleMouseEnter = (i) => (event) => {
    setCursor(i)
  }

  const selectHit = (ref) => {
    history.push(`/book/all/${ ref }`)
    setSearchValue('')
    setCursor(0)
  }

  const handleKeyEvent = (event) => {
    if(hasValue(hits) && event.keyCode === 38) {
      const prev = cursor === 0 ? 0 : cursor - 1
      handleScroll(refsArray.current[cursor], 'down')
      setCursor(prev)
    }
    if(hasValue(hits) && event.keyCode === 40) {
      const next = cursor === hits.length - 1 ? cursor : cursor + 1
      handleScroll(refsArray.current[cursor], 'up')
      setCursor(next)
    }
    if(hasValue(hits) && event.keyCode === 13) {
      selectHit(hits[cursor].uniqueRef)
    }
  }

  const handleClick = (ref) => () => {
    selectHit(ref)
  }

  /* eslint-disable */
  return (
    <div className="max-w-lg w-full lg:max-w-xs relative">
      <label htmlFor="search" className="sr-only">Search</label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </div>
        <input
          id="search"
          type="search"
          value={searchValue}
          onChange={onChangeDebounced}
          onKeyUp={ handleKeyEvent }
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out" placeholder="Search" alt="search"/>
      </div>
      <div className={`${hasValue(searchValue) && hasValue(hits) ? 'block' : 'hidden'} z-10 absolute mt-1 w-full bg-white rounded-md shadow-lg`}>
        <ul
          ref={ container }
          tabIndex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          className="max-h-60 rounded-md text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">

        {hits.map((hit, i) => (
          <li
            onMouseEnter={handleMouseEnter(i)}
            onClick={handleClick(hit.uniqueRef)}
            aria-labelledby={`listbox-option-${hit.uniqueRef}`}
            key={i}
            aria-selected="true"
            role="option"
            id={`listbox-option-${i}`}
            ref={ref => { refsArray.current[i] = ref; }}
            className={`${cursor === i ? "text-white bg-brand-primary" : "text-gray-900"} cursor-default select-none relative py-2 pl-3 pr-9`}
            >{hit.title}</li>
        ))}
        </ul>
      </div>
    </div>
  )
  /* eslint-enable */
};

const CustomAutocomplete = connectAutoComplete(Autocomplete)

const Search = () => {

  return (
    <InstantSearch searchClient={searchClient} indexName="book_contents_dev">
      <CustomAutocomplete delay={800}/>
    </InstantSearch>
  )
}

export default Search

