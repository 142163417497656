import CookieBanner from "../cookie-banner/cookie-banner";
import Nav from "../navigation/nav";
import React from "react";

const MasterLayout = ({ children }) => {
  return (
    <>
      <div className="min-h-screen bg-white">
        <Nav />
        <div className="">
          <main className="">
            <div className="max-w-full min-h-full mx-auto ">
              {children}
              <CookieBanner />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default MasterLayout;
