import React, { useState } from "react";
import { hasValue, createMarkup, trim, noop, sort, map, pipe, reduce } from "../../utils/utils";

import indications from '../../assets/images/drug/indications.png';
import cautions from '../../assets/images/drug/cautions.png';
import contraindications from '../../assets/images/drug/contraindications.png';
import pharmacokinetics from '../../assets/images/drug/pharmacokinetics.png';
import drugInteractions from '../../assets/images/drug/drug_interactions.png';
import adverseEffects from '../../assets/images/drug/adverse_effects.png';
import specialPrescribersPoints from '../../assets/images/drug/special.png';
import adultDose from '../../assets/images/drug/adult_dose.png';
import paediatricDose from '../../assets/images/drug/paediatric.png';
import preparations from '../../assets/images/drug/preparations.png';
import pregnancyAndLactation from '../../assets/images/drug/big-mother.png';

const icons = {
  "Indications": indications,
  "Cautions": cautions,
  "Contraindications": contraindications,
  "Pharmacokinetics": pharmacokinetics,
  "Drug interactions": drugInteractions,
  "Adverse effects": adverseEffects,
  "Spp": specialPrescribersPoints,
  "Adult dose": adultDose,
  "Paediatric dose": paediatricDose,
  "Preparations": preparations,
  "Pregnancy and lactation": pregnancyAndLactation,
}

const DrugBodyItem = ({ bodyItem }) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasChildren = hasValue(bodyItem.children);
  const hasIcon = trim(bodyItem.content).substr(0, 7) === "Special" ? icons["Spp"] : icons[trim(bodyItem.content)]

  return (
    <div className="border-b border-gray-200">
      <div
        onClick={hasChildren ? () => setIsOpen(!isOpen) : noop}
        className={`block ${ hasChildren && "hover:bg-gray-50"} focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out`}
      >
        <div className="flex items-center py-2">
          <div className="min-w-0 flex-1 flex flex-row items-center">
            { hasIcon &&
              <div className="pr-2">
                <img
                    className="h-6 w-auto"
                    src={hasIcon}
                    alt=""
                  />
              </div>
            }
            <div
              dangerouslySetInnerHTML={createMarkup(bodyItem.htmlContent)}
              className={`${hasChildren ? '' : 'server-content'}`}
            />
          </div>
          <div>

            {hasChildren && (
              <svg
                className={`h-5 w-5 ${
                  isOpen ? "text-brand-secondary" : "text-gray-900"
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
            )}
          </div>
        </div>
      </div>
      { hasChildren &&
        <div className={`${isOpen ? "block" : "hidden"} py-1`}>
          { hasChildren && renderBodyItemChildren(bodyItem.children) }
        </div>
      }
    </div>
  );
};

const renderBodyItemChildren = pipe(
  reduce((acc, next) => acc.concat(next.htmlContent), ""),
  x => (
    <div
      dangerouslySetInnerHTML={createMarkup(x)}
      className="server-content"
    />
  )
)
const renderBody = pipe(
  sort((x, y) => x.order > y.order),
  map((c, i) => <DrugBodyItem key={i} bodyItem={c} />)
)

const DrugBody = ({ content }) => {
  return (
    <div>
      {renderBody(content.body)}
    </div>
  );
};

export default DrugBody;

