import React, { createContext } from "react";
import ReactGA from 'react-ga';
import { noop } from '../utils/utils';

export const BookNavigationContext = createContext({
  currentPath: [],
  selectedItem: null,
  updateSelected: noop
});

export class BookNavigationProvider extends React.Component {
  constructor(props) {
    super(props)

    this.updateSelected = (item) => {

      ReactGA.event({
        category: item.type,
        action: 'Opened book item',
        label: item.title
      });

      if(item.parentId === null) {
        this.setState(s => ({
          ...s,
          currentPath: [item],
          selectedItem: item,
        }));

        return
      }

      this.setState(s => ({
        ...s,
        currentPath: s.currentPath.concat([item]),
        selectedItem: item
      }))
    }

    this.state = {
      currentPath: [],
      selectedItem: null,
      updateSelected: this.updateSelected
    }
  }


  render() {
    const { children } = this.props

    return (
      <BookNavigationContext.Provider value={this.state}>
        {children}
      </BookNavigationContext.Provider>
    );
  }
}
