import React, { Component } from 'react';
import BrowserDetection from 'react-browser-detection';
import Alert from '../alerts/alert';

const browserHandler = {
  ie: (browser) => <Alert message={ `You are using Internet Explorer. Please upgrade to the latest versions of Firefox, Edge or Chrome browsers to be able to use this application!` }/>,
  edge: (browser) => null,
  default: () => null
};

export default class BrowserWarning extends Component {
  render() {
    return (
      <BrowserDetection>
        { browserHandler }
      </BrowserDetection>
    );
  }
}
