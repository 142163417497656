import "./privacy-modal.css";

import React, { useEffect, useState } from "react";

import SAMFLogo from "../../assets/images/SAMF_logo.png";

const PrivacyModal = () => {
  let shown = localStorage.getItem("privacy-modal-shown");
  const [display, setDisplay] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  useEffect(() => {
    if (!shown && window.location.pathname === "/book") setDisplay(true);
  }, []);

  const onAcceptClicked = () => {
    localStorage.setItem("privacy-modal-shown", true);
    setDisplay(false);
  };

  return display ? (
    <div className="privacy-modal">
      <div className="privacy-modal-container">
        <img className="privacy-modal-logo" src={SAMFLogo} />
        <p className="privacy-modal-title">Agreement</p>
        <div className="privacy-modal-content">
          <p>I acknowledge that I have read and understand the following:</p>
          <br />
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(e) => setTermsChecked(e.target.checked)}
          />
          <label for="terms">
            <a
              class="text-brand-primary"
              href="/tos"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </label>
          <br></br>
          <input
            type="checkbox"
            id="privacy"
            name="privacy"
            onChange={(e) => setPrivacyChecked(e.target.checked)}
          />
          <label for="privacy">
            <a
              class="text-brand-primary"
              href="/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </label>
          <br />
          <br />
          <p>
            If you have additional questions or require more information about
            our{" "}
            <a
              class="text-brand-primary"
              href="/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            , do not hesitate to contact us through email at{" "}
            <a class="text-brand-primary" href="mailto:pha-mic@uct.ac.za">
              pha-mic@uct.ac.za
            </a>
            .
          </p>
          <br />
          <p>
            By using our website, you agree to our Terms and Conditions and
            Privacy Policy. To begin, accept below.
          </p>
        </div>
        <div
          className="accept-privacy-button"
          onClick={() => termsChecked && privacyChecked && onAcceptClicked()}
          style={{
            opacity: termsChecked && privacyChecked ? 1 : 0.5,
            cursor: termsChecked && privacyChecked ? "pointer" : "initial",
          }}
        >
          Accept
        </div>
      </div>
    </div>
  ) : null;
};

export default PrivacyModal;
