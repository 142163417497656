import React from 'react'
import { hasValue } from '../../utils/utils'
import defaultAvatar from '../../assets/images/multi.png';

const UserAvatar = ({ avatarUrl, size = 8 }) => { 
  return hasValue(avatarUrl) 
    ? <img className={`h-${ size } w-${ size } rounded-full`} src={ avatarUrl } alt="avatar"/>
    : <img className={`h-${ size } w-${ size } rounded-full`} src={ defaultAvatar } alt="avatar"/>
}

export default UserAvatar
