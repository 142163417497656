const config = {
  algolia: {
    appId: 'JBSB5JB5U1',
    apiKey: 'a4bd2e41ed7e28310b2d81d32083b449',
  },
  firebase: {
    apiKey: "AIzaSyAMNb-FBOaa-CS_mA91OR1RoLHw100KvdA",
    authDomain: "samf-b3abd.firebaseapp.com",
    databaseURL: "https://samf-b3abd.firebaseio.com",
    projectId: "samf-b3abd",
    storageBucket: "samf-b3abd.appspot.com",
    messagingSenderId: "934168600074",
    appId: "1:934168600074:web:48ae2904a253c60c1789f3",
    measurementId: "G-FW5B563WZ7"
  },
  baseUrl: "https://us-central1-samf-b3abd.cloudfunctions.net/api"
}

export default config
