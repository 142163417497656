import React, { useState } from "react";
import { createMarkup} from "../../../utils/utils";

const OnboardingItem = ({ itemTitle, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-gray-200">
      <div
        onClick={() => setIsOpen(!isOpen) }
        className={`block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out`}
      >
        <div className="flex items-center p-3">
          <div className="min-w-0 flex-1 flex flex-row items-center">
            <div dangerouslySetInnerHTML={createMarkup(itemTitle)}/>
          </div>
          <div>
              <svg
                className={`h-5 w-5 ${
                  isOpen ? "text-brand-secondary" : "text-gray-900"
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
          </div>
        </div>
      </div>
        <div className={`${isOpen ? "block" : "hidden"} px-5 pb-3`}>
          {children}
        </div>
    </div>
  );
};

export default OnboardingItem;

