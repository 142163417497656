import { Link, useRouteMatch } from "react-router-dom";
import React, { useContext, useState } from "react";

import UserAvatar from "../../components/user-avatar/user-avatar";
import { UserContext } from "../../contexts/user-context";
import logo from "../../assets/images/logo.png";

const NavLink = ({ to, children, activeOnlyWhenExact }) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Link
      to={to}
      className={`inline-flex items-center px-1 pt-1 border-b-2 border-${
        match ? "brand-primary" : "transparent"
      } text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-${
        match ? "brand-primary" : "gray-300"
      } transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
};

const MainNavLarge = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="hidden sm:-my-px sm:ml-6 space-x-8 sm:flex">
      <NavLink to="/" activeOnlyWhenExact={true}>
        Home
      </NavLink>
      <NavLink to="/about" activeOnlyWhenExact={true}>
        About SAMF
      </NavLink>
      <NavLink to="/about-us" activeOnlyWhenExact={true}>
        About us
      </NavLink>
      <NavLink to="/buy-samf" activeOnlyWhenExact={true}>
        Buy SAMF
      </NavLink>
      <NavLink to="/faq" activeOnlyWhenExact={true}>
        FAQ
      </NavLink>
      <NavLink to="/contact" activeOnlyWhenExact={true}>
        Contact
      </NavLink>

      {user && user.subscriptionError === undefined && (
        <NavLink to="/book">SAMF Content</NavLink>
      )}
    </div>
  );
};

const MainNavSmall = () => {
  return (
    <div className="pt-2 pb-3 space-y-1">
      <Link
        to="/"
        className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
      >
        Home
      </Link>

      <Link
        to="/about"
        className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
      >
        About
      </Link>

      <Link
        to="/buy-samf"
        className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
      >
        Buy SAMF
      </Link>

      <Link
        to="/faq"
        className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
      >
        FAQ
      </Link>

      <Link
        to="/contact"
        className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
      >
        Contact
      </Link>

      <Link
        to="/book"
        className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
      >
        SAMF Content
      </Link>
    </div>
  );
};

const UserMenuLarge = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, signOut } = useContext(UserContext);

  return user ? (
    <div className="hidden sm:ml-6 sm:flex sm:items-center">
      {/*<button className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out" aria-label="Notifications">
        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </svg>
    </button>*/}
      <div className="ml-3 relative">
        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
            id="user-menu"
            aria-label="User menu"
            aria-haspopup="true"
          >
            {user && <UserAvatar avatarUrl={user.avatarUrl} />}
          </button>
        </div>

        <div
          className={`${
            isOpen ? "opacity-100 scale-100" : "hidden opacity-0 scale-95"
          } origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10`}
        >
          <div className="py-1 rounded-md bg-white shadow-xs">
            <Link
              to="/profile"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out border-b border-gray-200 font-bold "
            >
              {user && user.displayName ? user.displayName : "Your Profile"}
            </Link>
            {/*<Link to="/" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
              Settings
            </Link>*/}

            <Link
              to="/medicine-info-services"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              Medicine Information Services
            </Link>
            <Link
              to="/abbreviations"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 border-b border-gray-200 ease-in-out"
            >
              Abbreviations
            </Link>

            <Link
              to="/buy-samf"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              Buy SAMF
            </Link>

            <Link
              to="/faq"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              FAQ
            </Link>

            <Link
              to="/contact"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 border-b border-gray-200 ease-in-out"
            >
              Contact us
            </Link>

            <Link
              to="/tos"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/privacy"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              Privacy Policy
            </Link>
            <Link
              to="/cookie-policy"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 border-b border-gray-200 ease-in-out"
            >
              Cookie Policy
            </Link>
            {/*<Link to="/contact" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
              Contact
            </Link> */}
            <div
              role="button"
              onClick={signOut}
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer"
            >
              Sign out
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="hidden sm:-my-px sm:ml-6 space-x-8 sm:flex">
      <NavLink to="/login">Login</NavLink>
    </div>
  );
};

const UserMenuSmall = () => {
  const { user, signOut } = useContext(UserContext);

  return user ? (
    <div className="pt-4 pb-3 border-t border-gray-200">
      <div className="flex items-center px-4 space-x-3">
        <div className="flex-shrink-0">
          {user && <UserAvatar avatarUrl={user.avatarUrl} />}
          {/*<img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>*/}
        </div>
        <div>
          <div className="text-base font-medium leading-6 text-gray-800">
            {user && user.displayName ? user.displayName : "Your Profile"}
          </div>
          <div className="text-sm font-medium leading-5 text-gray-500">
            {user.email}
          </div>
        </div>
      </div>
      <div
        className="mt-3 space-y-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <Link
          to="/profile"
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          role="menuitem"
        >
          Your Profile
        </Link>

        {/* <Link to="/" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">
          Settings
        </Link> */}
        <Link
          to="/tos"
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          role="menuitem"
        >
          Terms & Conditions
        </Link>
        <Link
          to="/privacy"
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          role="menuitem"
        >
          Privacy policy
        </Link>
        <Link
          to="/cookie-policy"
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          role="menuitem"
        >
          Cookie Policy
        </Link>
        <Link
          to="/medicine-info-services"
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          role="menuitem"
        >
          Medicine information services
        </Link>
        {/* <Link to="/faq" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">
          FAQ
        </Link> */}
        <Link
          to="/abbreviations"
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          role="menuitem"
        >
          Abbreviations
        </Link>
        {/* <Link to="/contact" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">
          Contact
        </Link> */}
        <div
          onClick={signOut}
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer"
          role="menuitem"
        >
          Sign out
        </div>
      </div>
    </div>
  ) : (
    <div className="pt-2 pb-3 space-y-1">
      <Link
        to="/login"
        className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
      >
        Login
      </Link>
    </div>
  );
};
const Nav = () => {
  const [show, setShow] = useState(false);
  // const {user} = useContext(UserContext);

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex flex-grow justify-between">
            <div className="flex-shrink-0 flex items-center">
              <div className="pr-4 flex justify-center items-center">
                <img
                  className="block lg:hidden h-8 w-auto"
                  src={logo}
                  alt="SAMF logo"
                />
                <img
                  className="hidden lg:block h-8 w-auto"
                  src={logo}
                  alt="SAMF logo"
                />
              </div>
              <div className="flex flex-col">
                <h1 className="text-2xl text-brand-primary font-bold">SAMF</h1>
                <h6 className="text-xs text-brand-secondary">
                  South African Medicines Formulary
                </h6>
              </div>
            </div>
            <MainNavLarge />
          </div>
          <UserMenuLarge />
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setShow(!show)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className={`${!show ? "block" : "hidden"} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${show ? "block" : "hidden"} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`${show ? "" : "hidden"} sm:hidden`}>
        <MainNavSmall />
        <UserMenuSmall />
      </div>
    </nav>
  );
};

export default Nav;
