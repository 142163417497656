import Footer from "../../components/footer/footer";
import HowToFindInfo from "../book/common/how-to-find-info";
import React from "react";
import WhatInfoToFind from "../book/common/what-info-to-find";
import android_voucher_guide from "../../assets/images/faq/android_voucher_flow.png";
import { createMarkup } from "../../utils/utils";
import ios_voucher_guide from "../../assets/images/faq/ios_voucher_guide.png";
import practice_manual from "../../assets/images/common/faq_practice_manual.png";
import delete_Profile from "../../assets/images/faq/Delete_profile.png";
import  offline from "../../assets/images/faq/Offline.PNG"


class FAQ extends React.Component {
  state = {
    filter: "",
    questionsAndAnswers: [
      {
        question: "How do I buy the SAMF app and access the SAMF website?",
        answer:
          "The app is available on the Apple Store and Play Store. Search for SAMF and follow the payment instructions. You will use your login and password created on the mobile app to access the web version of the SAMF. Go to <a class='text-brand-primary' href='https://samf-app.com/' target=\"_blank\" rel=\"noopener noreferrer\">https://samf-app.com/</a> and click on the login tab and fill in your login and password created on the mobile application.\n",
      },
      {
        question:
          "Do I need to pay separately for the SAMF app and SAMF website access?",
        answer: "No, buying the SAMF app allows access to the SAMF website.",
      },
      {
        question: "How much does the digital SAMF cost?",
        answer:
          "R889.99/year or R87.99/month (including VAT). This gives you access to both the SAMF app and website.",
      },
      {
        question:
          "Do pharmacies have to have the latest edition of SAMF, by law?",
        answer: "Yes, as detailed in the Good Pharmacy Practice Manual:",
        img: true,
        image: practice_manual,
      },
      {
        question: "How often is the SAMF updated?",
        answer:
          "The electronic version of the SAMF is updated approximately 6-monthly. ",
      },
      {
        question: "I can’t login to the website/App. What do I do?",
        answer:
          "Please e-mail us on <a class='text-brand-primary' href='mailto:pha-mic@uct.ac.za'>pha-mic@uct.ac.za</a> (office hours).\n",
      },
      // {
      //   question: 'How many devices am I allowed to log in to with my profile?',
      //   answer: 'TBD'
      // },
      {
        question: "I have forgotten my password. What do I do?",
        answer:
          "Click on ‘Forgot password’ on the login screen on your app, enter your e-mail address and we’ll send you a link to reset your password.",
      },
      {
        question:
          "Who do I contact if I would like to comment on the content in the SAMF?",
        answer:
          "Please contact the editor, Tashni Naidu on <a class='text-brand-primary' href='mailto:tashni.naidu@uct.ac.za'>tashni.naidu@uct.ac.za</a>.",
      },
      {
        question:
          "Who do I contact if I can’t find the information I am looking for in the SAMF?",
        answer:
          "<div>Please contact the Medicines Information Centre, based in the Division of Clinical Pharmacology at the University of Cape Town:</div>" +
          '<div class="flex">' +
          "<div><div>Tel:</div> <div>&nbsp;</div> <div>Fax:</div> <div>Email: </div> </div>" +
          '<div class="ml-3"> ' +
          "<div>(021) 4066829</div> " +
          "<div>(0861) 10 0531</div> " +
          "<div>(021) 448 0503</div> " +
          "<div><a class='text-brand-primary' href='mailto:pha-mic@uct.ac.za'>pha-mic@uct.ac.za</a>; <a class='text-brand-primary' target='_blank' rel=\"noopener noreferrer\"  href=\"http://www.mic.uct.ac.za\">www.mic.uct.ac.za</a> (office hours).</div> </div>" +
          "</div>",
      },
      {
        question: "Who do I contact if I experience technical problems?",
        answer:
          "Please e-mail us on <a class='text-brand-primary' href='mailto:pha-mic@uct.ac.za'>pha-mic@uct.ac.za</a> (office hours).",
      },
      {
        question: "Who do I contact if I have a sales query?",
        answer:
          "Please contact Annoesjka at <a class='text-brand-primary' href='mailto:annoesjka.swart@uct.ac.za'>  annoesjka.swart@uct.ac.za.</a>.",
      },
      {
        question: "Does the app work offline?",
        answer:
        "<p>Yes, the app can be used if you do not have access to the internet. The search function will still be available offline but it will have limited functionality.</p>"+
        "<p>In order to enable offline mode please use the offline mode toggle in the menu.</p>",
        img:true,
        image: offline,
      },
      {
        question: "Does the SAMF app have a medicines interaction checker?",
        answer:
          "No, you cannot check for interactions using multiple medicines, but most monographs include a section on interactions, and you can access the main interactions there.",
      },
      {
        question: "How do I find information?",
        howToFindInfo: true,
      },
      {
        question: "What information can I find?",
        whatInfoToFind: true,
      },
      {
        question: "Is there a print edition?",
        answer:
          "There is no print version of the 15th edition scheduled for publication in 2024. To enquire about earlier editions email<a class='text-brand-primary' href='mailto:samf@samedical.org'> samf@samedical.org</a>.",
      },
      {
        question: "How do I buy the printed SAMF?",
        answer:
          "There is no print version of the 15th edition scheduled for publication in 2024. To enquire about earlier editions email <a class='text-brand-primary' href='mailto:samf@samedical.org'> samf@samedical.org</a>.",
      },
      {
        question: "How much does the SAMF book cost?",
        answer: "R 991.30 excl. VAT",
      },
      {
        question: "Is there a SAMA member discount for the book?",
        answer:
          "<div>SAMA members: R 886.96 excl. VAT</div>" +
          "<div>Non SAMA members and second SAMA purchase: R 991.30 excl. VAT </div>" +
          "<div>Retailers: R 939.13 excl. VAT</div>",
      },
      {
        question: "Do you need a receipt/invoice for your app store purchase?",
        answer:
          "<div>Find instructions here:</div>" +
          '<div>Google Play: <a class=\'text-brand-primary\' target="_blank" href="https://support.google.com/store/answer/6160235?hl=en-GB">Understand your Google Store charges and receipts</a></div>' +
          '<div>Apple: <a class=\'text-brand-primary\' target="_blank" href="https://www.apple.com/shop/help/viewing_changing_orders#invoice">Viewing & Changing Orders on Apple</a></div>',
      },
      {
        question: "How do I use a voucher?",
        answer:
          "<b>For Android users</b>. As an example, the first step in the figure below shows a purchase screen for a " +
          "subscription. To enter a promo code, tap the arrow next to the current payment method to show the Payment" +
          " methods screen, as shown in step 2. Next, tap Redeem code to go to the Redeem a gift card or promo code screen, " +
          "as shown in step 3. You can then enter your promo code on this screen and tap Redeem to finish.",
        img: true,
        image: android_voucher_guide,
        answer2:
          "<b>For iOS users</b>. As an example, the first step in the figure below shows a purchase screen for a " +
          "subscription. To enter a promo code, tap on 'Redeem code' button shown in step 1. Step 2 requires you to " +
          "enter the voucher code and submit it. If the code is correct, in the final step you'll be asked to redeem the offer.",
        image2: ios_voucher_guide,
      },
      {
        question: "How do I cancel my subscription?",
        answer:
          "<p><b>For Android users</b></p>" +
          "<p>1. Open the Google Play app.</p>" +
          "<p>2. At the top right, tap the profile icon.</p>" +
          "<p>3. Tap Payments & subscriptions and then Subscriptions.</p>" +
          "<p>4. Select the subscription you want to cancel.</p>" +
          "<p>5. Tap Cancel subscription.</p>" +
          "<p>6. Follow the instructions.</p>" +
          "<br/>" +
          "<p><b>For iOS users</b></p>" +
          "<p>1. Open the phone Settings.</p>" +
          "<p>2. Tap on your name.</p>" +
          "<p>3. Tap Subscriptions.</p>" +
          "<p>4. Select the subscription that you want to manage.</p>" +
          "<p>5. Tap Cancel Subscription.</p>",
      },
      {
        question: "How to delete your account",
        answer:
        "<p><b>To delete your account, please follow these simple steps:</b></p> " +
        "<p> 1. Click on the burger menu in the top right corner.  </p>" +
        "<p> 2. Select “User Profile” </p>"+
        "<p> 3. Scroll to the bottom of the screen and select “edit data”.</p>" +
        "<p> 4. Scroll to the bottom of the screen and select “delete account”.</p>",
        img: true,
        image: delete_Profile,
      }
    ],
  };

  handleChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  render() {
    const { filter, questionsAndAnswers } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = questionsAndAnswers.filter((item) => {
      return Object.keys(item).some((key) =>
        key === "question" || key === "answer"
          ? item[key].toLowerCase().includes(lowercasedFilter)
          : false
      );
    });
    return (
      <div
        className="flex flex-col justify-between"
        style={{ minHeight: "95vh" }}
      >
        <div className="mx-2 md:mx-12 lg:mx-20">
          <div className="bg-white">
            <div className="mx-auto py-6 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:pt-16 lg:pb-11">
              <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
                <div className="space-y-5 sm:space-y-4">
                  <h1 className="text-4xl leading-9 font-extrabold tracking-tight sm:text-3xl text-brand-primary">
                    FAQs
                  </h1>
                </div>
              </div>
            </div>

            <div className="mx-auto px-4 max-w-screen-xl sm:px-6 lg:px-8 mb-15">
              <div className="w-1/2">
                <input
                  value={filter}
                  onChange={this.handleChange}
                  className="appearance-none block w-full mb-2 px-3 py-3 border border-gray-300 text-base leading-6 rounded-full placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
                  placeholder="Search"
                />
              </div>
              {filteredData.length === 0 ? (
                <div className="mt-5">No results found</div>
              ) : (
                <div></div>
              )}
              {filteredData.map((item, i) => (
                <div className="py-4" key={i}>
                  <div className="text-brand-primary font-bold text-xl mb-2">
                    {item.question}
                  </div>
                  <div dangerouslySetInnerHTML={createMarkup(item.answer)} />
                  {item.img ? (
                    <div className="mt-3">
                      <img
                        className="max-h-28-rem"
                        src={item.image}
                        alt="img"
                      />
                    </div>
                  ) : item.howToFindInfo ? (
                    <HowToFindInfo />
                  ) : item.whatInfoToFind ? (
                    <WhatInfoToFind />
                  ) : item.howToUseVoucher ? (
                    <div></div>
                  ) : (
                    <div></div>
                  )}
                  {item.answer2 ? (
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={createMarkup(item.answer2)}
                    />
                  ) : (
                    <div></div>
                  )}
                  {item.image2 ? (
                    <div className="mt-3">
                      <img
                        className="max-h-28-rem"
                        src={item.image2}
                        alt="img"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default FAQ;
