import React from 'react'
import three_phones from '../../assets/images/homepage/three_phones.png';
import hero_woman from '../../assets/images/homepage/samf-header-woman.png';
import male_doctor from '../../assets/images/homepage/male_doctor.png';
import Footer from '../../components/footer/footer'
import gplay_badge from "../../assets/images/common/gplay-badge.png";
import appstore_badge from "../../assets/images/common/appstore-badge.png";
import rephaim_mpofu from "../../assets/images/testimonials/rephaim_gray.png";
import briony from "../../assets/images/testimonials/briony_gray.png";
import annemarie_augustyn from "../../assets/images/testimonials/annemarie_gray.png";
import hannah_gunter from "../../assets/images/testimonials/hannah_gray.png";

import easy_access from "../../assets/images/core-features/rsz_easy_access.png";
import favourite from "../../assets/images/core-features/rsz_favourite.png";
import mic from "../../assets/images/core-features/rsz_mic.png";
import no_ads from "../../assets/images/core-features/rsz_no_ads.png";
import reviewed from "../../assets/images/core-features/rsz_1reviewed.png";
import search from "../../assets/images/core-features/rsz_search.png";
import support from "../../assets/images/core-features/rsz_support.png";
import update from "../../assets/images/core-features/rsz_update.png";
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {Link} from "react-router-dom";

const Home3 = () => {

  const testimonials = [
    {
      text: '“There is no better go-to reference than the SAMF. Having it on my phone will be like having 75 expert clinicians in my pocket!”',
      name: 'Briony Chisholm',
      qualification: 'Late-bloomer MSc. student',
      avatar: briony
    },
    {
      text: '“I have tried the app and used and compared to the paper copy as well. Well done. I don’t have any suggestions. It is excellent!”',
      name: 'Annemarie Augustyn',
      qualification: 'BSc (Dietetics, Pharmacy), BScMedScHons (Pharmacology)',
      avatar: annemarie_augustyn
    },
    {
      text: '“Having used the SAMF mobile app, as well as the website, I was thoroughly impressed by the functionality, ease of use, and overall design. The ability to favourite certain drugs was especially helpful for me! It really does increase the accessibility of this important clinical resource for the busy clinician. I will definitely recommend it to my colleagues.”',
      name: 'Rephaim Mpofu',
      qualification: 'MBChB, DipHIVMan, Clinical pharmacology registrar',
      avatar: rephaim_mpofu
    },
    {
      text: '“A must-have app for any healthcare worker or aspiring student. Helpful, thorough and up to date, this is the key bedside reference”',
      name: 'Hannah May Gunter',
      qualification: 'MBChB FCCP (SA)',
      avatar: hannah_gunter
    },
  ]
  return (
    <>
    <main className="md:relative bg-gray-200">
      <div className="flex flex-row z-10">
        <div className="px-8 mx-auto max-w-8xl w-full text-center md:text-left">
          <div className="pt-8 md:pt-16 xl:pt-30 lg:pl-12 xl:pl-24 pb-8 md:pb-0 md:w-full xl:w-5/6">
            <h3 className="text-brand-primary text-lg lg:text-xl xl:text-2xl">WELCOME</h3>
            <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 2xl:pt-2 leading-10 font-extrabold text-brand-primary sm:leading-none md:tracking-wider">
              TO THE SOUTH AFRICAN MEDICINES FORMULARY
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base lg:text-lg xl:text-xl 2xl:text-2xl 2xl:py-3 text-brand-primary md:mt-5 md:max-w-full ">
             You will now be able to access all of SAMF's content online. The University of Cape Town, Division of Clinical Pharmacology and the South African Medical Association are proud to deliver the SAMF content to your phone, your tablet and your desktop.
            </p>
            <p className="mt-3 max-w-md mx-auto text-base lg:text-lg xl:text-xl 2xl:text-2xl 2xl:py-0 text-brand-secondary md:mt-5 md:max-w-full font-bold">
              15<sup>th</sup> EDITION CONTENT NOW ONLINE!

            </p>
            <p className="mt-3 max-w-md mx-auto text-base lg:text-lg xl:text-s 2xl:text-s 2xl:py-0 text-black md:mt-0 md:max-w-full font-bold italic ">
              LAST UPDATED: FEBRUARY 2024
            </p>

            <div className='flex mt-5 mb-2 2xl:mt-10 justify-center md:justify-start z-30'>
              <a className='z-30 mr-4' target='_blank' rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.aviro.samf'>
                <img className='store-badge-home' src={ gplay_badge } alt="Available on Google Play"/>
              </a>
              <a className='z-30' target='_blank' rel="noopener noreferrer" href='https://apps.apple.com/us/app/samf/id1574380535'>
                <img className='store-badge-home' src={ appstore_badge } alt="Available on App Store"/>
              </a>
            </div>
          </div>
        </div>
        <div className="hidden md:block relative w-full h-64 sm:h-72 md:h-96 lg:inset-y-0 lg:right-0 lg:w-2/3 lg:h-full flex justify-center overflow-hidden">
          <img className="max-w-full " src={ hero_woman } alt="Doctors and nurses"/>
        </div>
      </div>
      <div className="hidden md:block bg-blue-900 bg-opacity-50 w-full md:h-20 lg:h-28 xl:h-36 2xl:h-48 z-20 md:absolute bottom-0"></div>
    </main>

    <div className="mx-auto max-w-8xl w-full bg-white pt-8 pb-2 px-4 sm:px-8">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className=" flex flex-col justify-around md:max-w-2xl md:mx-auto lg:col-span-5 lg:text-left">
          <div className="md:pt-16 lg:pt-10">
            <h2 className="mb-8 text-brand-primary uppercase text-lg tracking-tight leading-10 text-white sm:leading-none sm:text-xl lg:text-2xl xl:text-3xl">Why SAMF ?</h2>
            <div className="p-8 bg-corner-image">
              <p className="font-light text-brand-primary w-2/3 lg:text-lg">
                Regarded as a gold standard of medicine information in South Africa, the SAMF is a trusted source of information and provides up-to-date and importantly, reliable information on medicines.
              </p>
              <p className="font-light text-brand-primary w-2/3 lg:text-lg">
                See <Link to="/ciprofloxacin" className="italic text-brand-secondary hover:text-green-600">
                example of content
              </Link>.
              </p>
            </div>
          </div>

          <div className="hidden lg:block relative w-full overflow-hidden pl-10 mt-8">
            <img className="max-w-full " src={ male_doctor } alt="Doctor"/>
          </div>
        </div>
        <div className=" sm:text-center md:max-w-8xl md:mx-auto lg:col-span-7 lg:text-left ">
          <img className="w-full" src={ three_phones } alt="SAMF Mobile app"/>

          <div className=" sm:text-center md:max-w-8xl md:mx-auto lg:col-span-6 lg:text-left lg:w-full md:w-4/5 px-6
          md:px-10">
            <div className="pt-8">
              <div className='core-features px-10 py-10 font-light text-brand-primary'>
                <div className='text-2xl font-bold mb-4'>Core Features</div>
                <div className='mb-3 text-brand-secondary font-bold'>
                  Up-to-date, unbiased information in your pocket</div>

                <div className='flex flex-wrap'>
                  <div className='w-1/2'>
                    <img className='core-features-icons' src={ favourite } alt="favourites"/>
                    <div className='mb-2'>Favourites list</div>
                    <img className='core-features-icons' src={ search } alt="search"/>
                    <div className='mb-2'>Powerful search function</div>
                    <img className='core-features-icons' src={ reviewed } alt="reviewed"/>
                    <div className='mb-2'>Reviewed by respected clinicians in their field</div>
                    <img className='core-features-icons' src={ easy_access } alt="easy_access"/>
                    <div className='mb-2'>Easy access</div>
                  </div>
                  <div className='w-1/2'>
                    <img className='core-features-icons' src={ update } alt="updated"/>
                    <div className='mb-2'>Updated every 6 months</div>
                    <img className='core-features-icons' src={ no_ads } alt="no-ads"/>
                    <div className='mb-2'>Advert free</div>
                    <img className='core-features-icons' src={ mic } alt="mic"/>
                    <div className='mb-2'>Voice search function</div>
                    <img className='core-features-icons' src={ support } alt="support"/>
                    <div className='mb-2'>Support by the Medicines Information Centre, UCT</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <Carousel
        className='px-4 py-5'
        plugins={['infinite', 'arrows', { resolve: slidesToShowPlugin, options: { numberOfSlides: 3 }},
          // { resolve: autoplayPlugin, options: { interval: 5000}}
          ]}
        breakpoints={{
          900: { plugins: ['infinite', 'arrows', {resolve: slidesToShowPlugin, options: {numberOfSlides: 1}}]},
          1300: { plugins: ['infinite', 'arrows', {resolve: slidesToShowPlugin, options: {numberOfSlides: 2}}]},
        }}
        // animationSpeed={1000}
      >
          {testimonials.map((testimonial, i) => (
            <div className='flex h-100' style={{minHeight:'450px', height:'100%'}} key={i}>
              <div className='bg-brand-secondary bg-opacity-25 m-8 core-features p-5 flex flex-col justify-around'>
                <div className='flex justify-center pb-3'>
                  <div className='h-32 w-32'>
                  <img className='rounded-full h-32 w-32' alt='Avatar' src={ testimonial.avatar }></img>
                  </div>
                </div>
                <div className='text-base italic mb-4'>
                  {testimonial.text}
                </div>
                <div className='text-sm mx-6 ' style={{textAlign: "end"}}>
                  <div className='w-12 ml-auto mb-2'>
                    <hr className='bg-brand-secondary h-1'/>
                  </div>
                  <div>
                    {testimonial.name}
                  </div>
                  <div>
                    {testimonial.qualification}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Carousel>

      <Footer/>

    </>
  )
}

export default Home3;
