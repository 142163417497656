import React from 'react';
import logo from '../../assets/images/logo.png';
import {isEmpty, hasKey} from '../../utils/utils'
import {useForgotPassword} from "../../hooks/useFirebaseAuthOps";
import useForm from "../../hooks/useForm";

const validateResetPassword = (form) => {
  let errors = {  }
  if(form.email) {
    const valid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(form.email)

    errors = valid ? errors : { ...errors, email: 'Email not correct!' }
  }

  if(form.email === undefined){
    errors = { ...errors, email: 'Email is required!' }
  }
  return errors;
}

const ForgotPassword = (props) => {
  // const forgotPassword = useFirebaseAuthOps(setError, (x) => console.log(x))

  const [upload, loading, reply] = useForgotPassword();
  const handleSubmit = (form, errs) => {
    if(isEmpty(errs) && !loading) {
      upload({form})
      return form;
    }
    return form;
  }
  const [formValues, onChange, onSubmit, errors] = useForm({email: ''}, handleSubmit, validateResetPassword)

  return (
    <div className="pt-24 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <img className="mx-auto h-12 w-auto" src={logo} alt="SAMF"/>
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-brand-primary">
          Forgot password
        </h2>
        <div className="mt-2 text-center text-large">
          Enter the e-mail you used in the registration step.
        </div>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                name="userEmail"
                value = {formValues.email}
                placeholder="E.g: faruq123@gmail.com"
                id="userEmail"
                onChange = {onChange('email')}
                type="email"
                required
                className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${ hasKey('email')(errors) ? 'border-4 border-red-300 bg-red-100' : 'border-gray-300'}`}/>
              </div>
            </div>
            {reply.success && <div className="mt-3 p-1 text-xs text-green-400">Your request has been registered! Please check your e-mail for further instructions!</div>}
            {reply.error && <div className="p-1 text-xs text-red-400">Something happened! {reply.error}</div>}

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                 <button onClick={ onSubmit }
                           className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-secondary hover:bg-green-400 focus:outline-none focus:border-green-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                         {/*className="mt-3 px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-brand-secondary shadow-sm hover:bg-green-300 focus:outline-none focus:border-green-900 focus:shadow-outline-gray active:bg-green-800 transition duration-150 ease-in-out sm:mt-3 sm:flex-shrink-0 sm:inline-flex sm:items-center">*/}
                 {
                    loading
                    ? 'Loading...'
                    : 'Reset password'
                  }
                 </button>
                {/*<button*/}
                {/*  onClick = {forgotPasswordHandler(email)}*/}
                {/*  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-secondary hover:bg-green-400 focus:outline-none focus:border-green-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">*/}
                {/*  Forgot password*/}
                {/*</button>*/}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;
