import production from './config.prod'
import development from  './config.dev'
import integration from './config.int'

import { hasValue } from '../utils/utils'

const nodeEnv = process.env.NODE_ENV;
const deployTarget = process.env.REACT_APP_DEPLOY_TARGET;
const env = hasValue(deployTarget) ? deployTarget : nodeEnv
console.log("ENV", env)

const config = {
  production,
  development,
  integration
}

export default config[env]
