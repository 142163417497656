import React from 'react';
import { createMarkup} from '../../utils/utils';


const OtherContentHeader = ({ content }) => {

  return (
    <h3 className="text-lg leading-6 font-medium text-white">
      <div dangerouslySetInnerHTML={createMarkup(content.name)}/>
    </h3>
  )
}

export default OtherContentHeader;